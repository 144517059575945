import { useState, useEffect, useRef } from 'react'
import { Link } from 'wouter'
import Dashboard from '../../components/Dashboard'
import DataTableC from '../../components/DataTable'
import AddUser from '../../components/AddUser'
import UploadFile from '../../components/UploadFile'
import Especiales from '../../components/Especiales'
import Promociones from '../../components/Promociones'
import UsersTable from '../../components/DataTable/UsersTable'
import ChartComponent from '../../components/Chart'
import AdminSupervisor from '../../components/AdminCount/AdminSupervisor'
import AdminVendedor from '../../components/AdminCount/AdminVendedor'
import AutosTable from '../../components/DataTable/AutosTable'
import InsuranceTable from '../../components/DataTable/InsuranceTable'
import UploadDocument from '../../components/UploadDocument'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useLogin } from '../../hooks/useLogin'
import { Tooltip } from 'primereact/tooltip'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { putUserUrl,version_Movex } from '../../helpers/helper'
import { Toast } from 'primereact/toast'
// import Parameters from '../../components/Parameters'
import UpdateParams from '../../components/Parameters/UpdateParams'

//Admin Page
export default function Admin ( {isLogged} ) {
  const profile = useRef()
  const toast = useRef(null)
  const [ vendedor, setVendedor ] = useState(false)
  const [ supervisor, setSupervisor ] = useState(false)
  const [ archivos, setArchivos ] = useState(false)
  const [ reportes, setReportes ] = useState(false)
  const { setRoute, setUser, component, setComponent, user } = useLogin()
  const [ btnStatus, setBtnStatus ] = useState([true, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false])

  useEffect(() => {
    setUser(isLogged)
    setRoute('/admin')
  }, [isLogged, setRoute, setUser])

  const showInfo = (message) => {
    toast.current.show({severity:'success', summary: 'Success', detail: message, life: 3000})
  }

  const handlerSubmit = (e) => {
    e.preventDefault()
    profile.current.toggle(false)
    let updateUser = {}
    let nombres = '', apellido_materno = '', apellido_paterno = '', contrasenia = ''
    nombres = ( document.getElementById('nombres').value !== undefined && document.getElementById('nombres').value !== null && document.getElementById('nombres').value !== '') 
    ? document.getElementById('nombres').value : user.nombres
    apellido_paterno = ( document.getElementById('apellidoPaterno').value !== undefined && document.getElementById('apellidoPaterno').value !== null && document.getElementById('apellidoPaterno').value !== '' ) 
    ? document.getElementById('apellidoPaterno').value : user.apellido_paterno
    apellido_materno = ( document.getElementById('apellidoMaterno').value !== undefined && document.getElementById('apellidoMaterno').value !== null && document.getElementById('apellidoMaterno').value !== '' ) 
    ? document.getElementById('apellidoMaterno').value : user.apellido_materno
    contrasenia = ( document.getElementById('contrasenia').value !== undefined && document.getElementById('contrasenia').value !== null && document.getElementById('contrasenia').value !== '' ) 
    ? document.getElementById('contrasenia').value : ''
    contrasenia === '' ? updateUser = {id: user.id, nombres: nombres, apellido_paterno: apellido_paterno, apellido_materno: apellido_materno } :
    updateUser = {id: user.id, nombres: nombres, apellido_paterno: apellido_paterno, apellido_materno: apellido_materno, contrasenia: contrasenia }
    console.log(updateUser)

    fetch(putUserUrl, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateUser),
    }).then((res) => res.json())
      .then((result) => {
        result.message === 'Success' && showInfo('Los datos fueron actualizados.')
      })
  }
  
  return(
    <>
      <Toast ref={toast} />
      <Tooltip target='.home' content={`Inicio`}/>
      <Tooltip target='.profile' content={`Editar Perfil`}/>

      <OverlayPanel ref={profile} showCloseIcon id='overlay_panel' style={{backgroundColor: '#f3f4f6'}}>
        <form className='px-4 pb-4 p-fluid' onSubmit={handlerSubmit}>
          <h2 className='font-semibold mb-4 text-center text-lg'>Actualizar Datos del Perfil</h2>
          <InputText id='nombres' defaultValue={user.nombres} style={{height: '2rem', margin: '0.5rem 0'}}
          className="my-4 text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
          <InputText id='apellidoPaterno' defaultValue={user.apellido_paterno} style={{height: '2rem', margin: '0.5rem 0'}}
          className="my-4 text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
          <InputText id='apellidoMaterno' defaultValue={user.apellido_materno} style={{height: '2rem', margin: '0.5rem 0'}}
          className="my-4 text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
          <Password inputId='contrasenia' placeholder='Contraseña' toggleMask inputStyle={{height: '2rem'}}
          className="text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
          <button className='my-5 bg-indigo-500 text-white py-2 w-full rounded-lg hover:bg-indigo-600'>Actualizar</button>
        </form>
      </OverlayPanel>

      <div className='flex flex-row'>
        {/* SideBar */}
        <aside className='sticky top-0 h-screen w-64 bg-gray-900 rounded-r-[2.4rem] ring-8 ring-indigo-300'>
          {/* <Link to='#'><h2 className='text-gray-100 font-semibold text-xl text-center my-10'>MOVEX MX</h2></Link> */}
          <Link to='#'><img src='imagotipo.png' alt='logoMovex' className='lg:w-[5rem] md:w-[4rem] w-[3rem] mx-auto mt-10 mb-5' /></Link>
          <div className='ml-3'>
            {/* <button className={ btnStatus[0] ? 'text-lg px-5 py-1 bg-white text-gray-900 w-full text-start rounded-l-full my-2' 
            : 'text-lg text-white px-5 py-1 hover:bg-white hover:text-gray-900 w-full text-start rounded-l-full my-2' } 
            onClick={() => {
              setComponent('dashboard')
              const arr = Array(17).fill(false)
              arr[0] = true
              setBtnStatus(arr) 
            }}><i className='pi pi-home mr-2'></i> Inicio</button> */}
            <button className={ btnStatus[1] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-1' 
              : 'text-lg text-white px-5 py-1 hover:bg-gray-700 w-full text-start rounded-l-full my-1' } 
              onClick={() => {
                setComponent('cotizador')
                const arr = Array(20).fill(false)
                arr[1] = true
                setBtnStatus(arr)  
            }}><i className='pi pi-money-bill mr-2'></i> Cotizador</button>

            <button className={ btnStatus[20] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-1' 
              : 'text-lg text-white px-5 py-1 hover:bg-gray-700 w-full text-start rounded-l-full my-1' } 
              onClick={() => {
                setComponent('especiales')
                const arr = Array(21).fill(false)
                arr[20] = true
                setBtnStatus(arr)  
            }}><i className='pi pi-percentage mr-2'></i> Promociones</button>

            <button className={ btnStatus[17] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-1' 
            : 'text-lg text-white px-5 py-1 hover:bg-gray-700 w-full text-start rounded-l-full my-1'} 
            onClick={() => {
              setReportes(!reportes)
              setArchivos(false)
              setSupervisor(false)
              setVendedor(false)
              const arr = Array(20).fill(false)
              arr[17] = true
              setBtnStatus(arr) 
            }}
            ><i className='pi pi-pencil mr-2'></i> Reportes</button>
            {
              reportes &&
              <div className='mb-4'>
                <button className={ btnStatus[18] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start' 
                : 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start' } 
                  onClick={() => {
                    setComponent('carTable')
                    const arr = Array(20).fill(false)
                    arr[18] = true
                    setBtnStatus(arr)  
                  }}>Autos</button>
                <button className={ btnStatus[19] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start' 
                : 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start' } 
                  onClick={() => {
                    setComponent('insuranceTable')
                    const arr = Array(20).fill(false)
                    arr[19] = true
                    setBtnStatus(arr)  
                  }}>Seguros</button>
              </div>
            }

            <button className={ btnStatus[2] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-1' 
            :'text-lg text-white px-5 py-1 hover:bg-gray-700 w-full text-start rounded-l-full my-1' } 
            onClick={() => {
              setSupervisor(!supervisor)
              setReportes(false)
              setVendedor(false)
              setArchivos(false)
              const arr = Array(20).fill(false)
              arr[2] = true
              setBtnStatus(arr) 
            }}
            ><i className='pi pi-user mr-2'></i> Supervisores</button>
            { supervisor &&
              <div className='mb-4'>
                <button className={btnStatus[3] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start'
                : 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start'}
                onClick={() => {
                  setComponent('datosSup')
                  const arr = Array(20).fill(false)
                  arr[3] = true
                  setBtnStatus(arr) 
                }}>Reportes</button>
                {/* <button className={btnStatus[3] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start'
                : 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start'}
                onClick={() => {
                  setComponent('cotizacionesSup')
                  const arr = Array(17).fill(false)
                  arr[3] = true
                  setBtnStatus(arr) 
                }}>Cotizaciones</button> */}
                {/* <button className={ btnStatus[4] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start'
                : 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start'}
                onClick={() => {
                  setComponent('ventasSup')
                  const arr = Array(17).fill(false)
                  arr[4] = true
                  setBtnStatus(arr) 
                }}> Cotizaciones - Ventas</button> */}
                <button className={ btnStatus[5] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start' :
                'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start' }
                onClick={() => {
                  setComponent('adminSup')
                  const arr = Array(17).fill(false)
                  arr[5] = true
                  setBtnStatus(arr) 
                }}>Administrar Cuenta</button>
                <button className={ btnStatus[6] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start' : 
                'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start' } 
                onClick={() => {
                  setComponent('addSup')
                  const arr = Array(20).fill(false)
                  arr[6] = true
                  setBtnStatus(arr)                           
                }}>Agregar Cuenta</button>
              </div>
            }

            <button className={ btnStatus[7] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-1' 
            : 'text-lg text-white px-5 py-1 hover:bg-gray-700 w-full text-start rounded-l-full my-1'} 
            onClick={() => {
              setVendedor(!vendedor)
              setReportes(false)
              setSupervisor(false)
              setArchivos(false)
              const arr = Array(20).fill(false)
              arr[7] = true
              setBtnStatus(arr) 
            }}
            ><i className='pi pi-user mr-2'></i> Asesores</button>
            { vendedor && 
              <div className='mb-4'>
                <button className={ btnStatus[8] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start' 
                : 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start' }
                onClick={() => {
                  setComponent('datosVend')
                  const arr = Array(20).fill(false)
                  arr[8] = true
                  setBtnStatus(arr)
                }}>Reportes</button>
                {/* <button className={ btnStatus[9] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start' 
                : 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start' }
                onClick={() => {
                  setComponent('cotizacionesVend')
                  const arr = Array(17).fill(false)
                  arr[9] = true
                  setBtnStatus(arr)
                }}
                >Cotizaciones</button> */}
                {/* <button className={ btnStatus[10] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start' 
                : 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start' } 
                onClick={() => {
                  setComponent('ventasVend')
                  const arr = Array(17).fill(false)
                  arr[10] = true
                  setBtnStatus(arr)
                }}>Cotizaciones - Ventas</button> */}
                <button className={ btnStatus[11] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start'
                :'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start' }
                onClick={() => {
                  setComponent('adminVend')
                  const arr = Array(20).fill(false)
                  arr[11] = true
                  setBtnStatus(arr)
                }}>Administrar Cuenta</button>
                <button className={ btnStatus[12] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start'
              :'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start' } 
                onClick={() => {
                  setComponent('addVend')
                  const arr = Array(20).fill(false)
                  arr[12] = true
                  setBtnStatus(arr)  
                }}>Agregar Cuenta</button>
              </div>
            }
            
            <button className={ btnStatus[13] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-1' 
            : 'text-lg text-white px-5 py-1 hover:bg-gray-700 w-full text-start rounded-l-full my-1'} 
            onClick={() => {
              setArchivos(!archivos)
              setReportes(false)
              setSupervisor(false)
              setVendedor(false)
              const arr = Array(20).fill(false)
              arr[13] = true
              setBtnStatus(arr) 
            }}
            ><i className='pi pi-cloud-upload mr-2'></i> Subir Archivo csv</button>
            {
              archivos &&
              <div className='mb-4'>
                <button className={ btnStatus[14] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start' 
                : 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start' } 
                  onClick={() => {
                    setComponent('uploadAutos')
                    const arr = Array(20).fill(false)
                    arr[14] = true
                    setBtnStatus(arr)  
                  }}>Autos</button>
                <button className={ btnStatus[15] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start' 
                : 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start' } 
                  onClick={() => {
                    setComponent('uploadSeguros')
                    const arr = Array(20).fill(false)
                    arr[15] = true
                    setBtnStatus(arr)  
                  }}>Seguros</button>
                <button className={ btnStatus[16] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start' 
                : 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 hover:bg-gray-700 rounded-l-full text-start' } 
                  onClick={() => {
                    setComponent('uploadAgencias')
                    const arr = Array(20).fill(false)
                    arr[16] = true
                    setBtnStatus(arr)  
                  }}>Agencias</button>
              </div>
            }

            <button className={ btnStatus[22] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-1' 
              : 'text-lg text-white px-5 py-1 hover:bg-gray-700 w-full text-start rounded-l-full my-1' } 
                onClick={() => {
                  setComponent('documents')
                  const arr = Array(23).fill(false)
                  arr[22] = true
                  setBtnStatus(arr) 
              }}><i className='pi pi-file-import mr-2'></i> Documentos</button>
          </div>


          <div className='absolute inset-x-0 bottom-0 my-10 mr-3'>
            <button className={ btnStatus[21] ? 'text-lg text-white px-10 py-1 bg-gray-700 w-full text-start rounded-l-full my-2' 
              : 'text-lg text-white px-10 py-1 hover:bg-gray-700 w-full text-start rounded-l-full my-2' } 
              onClick={() => {
                setComponent('parametros')
                const arr = Array(22).fill(false)
                arr[21] = true
                setBtnStatus(arr)  
            }}><i className='pi pi-wrench mr-2'></i> Parametros</button>
            <Link to='/logout' className='text-lg text-white px-10 py-1 hover:bg-gray-600 w-full text-end rounded-r-full my-2' onClick={() => {
                setRoute('/')
                setUser({})
              }}>
              <i className='pi pi-sign-out mr-2'></i> Cerrar Sesión
            </Link>
            {/* <p className='text-white pl-10 pt-5 text-[0.75rem]'>Cotizador Movex v.1.3.2 by BMTools</p> */}
            <p className='text-white pl-10 pt-5 text-[0.75rem]'>{version_Movex}</p>
          </div>
        </aside>

        <section className='mx-auto w-5/6'>
          <div className='mx-10 my-5 flex justify-start'>
            <button className='border-2 text-indigo-700 border-indigo-700 hover:bg-indigo-50 rounded-lg home mr-4' onClick={() => {
              setComponent('dashboard')
              setBtnStatus([true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
            }}>
              <i className='pi pi-home p-2' style={{'fontSize': '1.6rem'}}></i></button>
            <button className='border-2 text-indigo-700 border-indigo-700 hover:bg-indigo-50 rounded-lg profile mr-4' onClick={(e) => profile.current.toggle(e)}>
              <i className='pi pi-user-edit p-2' style={{'fontSize': '1.6rem'}}></i></button>
            {/* <Parameters /> */}
          </div>
         {
            component === 'dashboard' ? <Dashboard /> 
            : component === 'datosSup' ? <UsersTable tipo='supervisor' title='Supervisores' />
            : component === 'cotizacionesSup' ? <DataTableC />
            : component === 'ventasSup' ? <ChartComponent />
            : component === 'adminSup' ? <AdminSupervisor tipo='supervisor' />
            : component === 'addSup' ? <AddUser type='Supervisor' />
            : component === 'datosVend' ? <UsersTable tipo='vendedor' title='Asesores' />
            : component === 'cotizacionesVend' ? <DataTableC />
            : component === 'ventasVend' ? <ChartComponent />
            : component === 'adminVend' ? <AdminVendedor tipo='vendedor' />
            : component === 'addVend' ? <AddUser type='Vendedor' />
            : component === 'uploadAutos' ? <UploadFile label='Autos' />
            : component === 'uploadSeguros' ? <UploadFile label='Seguros' />
            : component === 'uploadAgencias' ? <UploadFile label='Agencias' />
            : component === 'cotizador' ? <Especiales />
            : component === 'carTable' ? <AutosTable /> 
            : component === 'insuranceTable' ? <InsuranceTable />
            : component === 'especiales' ? <Promociones />
            : component === 'parametros' ? <UpdateParams />
            : component === 'documents' ? <UploadDocument />
            : <Dashboard />
         }
        </section>
      </div>
    </>
  )
}