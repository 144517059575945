import { useLogin } from "../../hooks/useLogin"
import { Link } from 'wouter'

export default function Header () {
  const { route } = useLogin()
  return (
    <>
      <header className="px-5 py-4 bg-gray-900 border-b-4 border-indigo-400 grid grid-cols-2 sticky top-0 absolute z-50">
        <Link to={route}><img src="/logomovex.svg" alt="Movex Logo" className="h-[2.5rem] bg-white px-2 py-1 mx-5 rounded-lg" /></Link>
        {/* { route !== '/' && <Link to='/logout' className="text-white justify-self-end mx-5 py-2 text-lg" onClick={() => {
          setRoute('/')
          setUser({})
        }}><i className="pi pi-sign-out mr-2"></i>Cerrar Sesión</Link> } */}
      </header>
    </>
  )
}