import { InputNumber } from 'primereact/inputnumber'
import { pmt } from '../../helpers/helper'
import { useCotizacion } from '../../hooks/useCotizacion'

export default function ArrendamientoSA () {
  const  { valorFactura, parametros,
     residualf5710
     } = useCotizacion()
  // setResidualf5710([valorFactura*0.05,valorFactura*0.07,valorFactura*0.10])
  // console.log("ya esta echo para administrador y supervisor")
  // console.log("residual Fijooooo747777777 ")
  // console.log(residualf5710)
  // console.log("residual Fijooooo porque aculizar ")
  // console.log(residualf5710)
  // console.log("rvalorFactura Fijooooo porque aculizar ")
  // console.log(valorFactura)
  return (
    <>
      <section>
        <h4 className='my-5 font-semibold text-lg'>Arrendamiento Sin Aportación <span>{`(Renta mensual sin I.V.A)`}</span></h4>
        <div className='p-fluid grid grid-cols-7 gap-2 mb-2'>
          <div className='col-span-2'></div>
          <p className='col-span-2 bg-gray-200 rounded rounded-md grid content-center py-2 w-full'>Renta Mensual</p>
          <p className='col-span-3 bg-gray-200 rounded rounded-md grid content-center py-2 w-full'>Residual</p>
         
        </div>
        <div className='p-fluid grid grid-cols-7 gap-2 mb-4'>
          <p className='col-span-2 bg-gray-200 rounded rounded-md grid content-center'>24 Meses</p>
          <InputNumber placeholder='24 Meses sin Aportacion' value={valorFactura !== 0 ? pmt(24, -valorFactura, valorFactura*0.5, parametros.rate) : 0} disabled mode="currency" currency="USD" locale="en-US"
          className="col-span-2 relative block w-full rounded-none rounded-t-md text-gray-900 sm:text-sm" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}/>
          <aside className='grid grid-cols-3 gap-1 col-span-3'>
            {/* <InputNumber placeholder='Residual' value={valorFactura !== 0 ? valorFactura * 0.5 : 0} disabled mode="currency" currency="USD" locale="en-US"
            className="col-span-2 relative block w-full rounded-none rounded-t-md text-gray-900 sm:text-sm" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}/> */}
            <InputNumber placeholder='Residual' value={valorFactura !== 0 ? (valorFactura * 0.5)+residualf5710[0] : 0} disabled mode="currency" currency="USD" locale="en-US"
            className="col-span-2 relative block w-full rounded-none rounded-t-md text-gray-900 sm:text-sm" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}/>
            {/* <InputNumber inputId="percent" value={ 50 } suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 col-span-1"  */}
            <InputNumber inputId="percent" value={ 55 } suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 col-span-1" 
            inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}} disabled/>
          </aside>
            
        </div>
        <div className='p-fluid grid grid-cols-7 gap-2 mb-4'>
          <p className='col-span-2 bg-gray-200 rounded rounded-md grid content-center'>36 Meses</p>
          <InputNumber value={valorFactura !== 0 ? pmt(36, -valorFactura, valorFactura*0.4, parametros.rate) : 0} disabled mode="currency" currency="USD" locale="en-US"
          className="col-span-2 relative block w-full rounded-none rounded-t-md text-gray-900 sm:text-sm" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}/>
          <aside className='grid grid-cols-3 gap-1 col-span-3'>
            {/* <InputNumber placeholder='Residual' value={valorFactura !== 0 ? valorFactura * 0.4 : 0} disabled mode="currency" currency="USD" locale="en-US" */}
            <InputNumber placeholder='Residual' value={valorFactura !== 0 ? (valorFactura * 0.4)+residualf5710[1] : 0} disabled mode="currency" currency="USD" locale="en-US"
            className="col-span-2 relative block w-full rounded-none rounded-t-md text-gray-900 sm:text-sm" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}/>
            {/* <InputNumber inputId="percent" value={ 40 } suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 col-span-1"  */}
            <InputNumber inputId="percent" value={ 47 } suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 col-span-1" 
            inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}} disabled/>
          </aside>
            
        </div>
        <div className='p-fluid grid grid-cols-7 gap-2 mb-4'>
          <p className='col-span-2 bg-gray-200 rounded rounded-md grid content-center'>48 Meses</p>
          <InputNumber value={valorFactura !== 0 ? pmt(48, -valorFactura, valorFactura*0.3, parametros.rate) : 0} disabled mode="currency" currency="USD" locale="en-US"
          className="col-span-2 relative block w-full rounded-none rounded-t-md text-gray-900 sm:text-sm" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}/>
          <aside className='grid grid-cols-3 gap-1 col-span-3'>
            {/* <InputNumber placeholder='Residual' value={valorFactura !== 0 ? valorFactura * 0.3 : 0} disabled mode="currency" currency="USD" locale="en-US" */}
            <InputNumber placeholder='Residual' value={valorFactura !== 0 ? (valorFactura * 0.3)+residualf5710[2] : 0} disabled mode="currency" currency="USD" locale="en-US"
            className="col-span-2 relative block w-full rounded-none rounded-t-md text-gray-900 sm:text-sm" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}/>
            {/* <InputNumber inputId="percent" value={ 30 } suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 col-span-1"  */}
            <InputNumber inputId="percent" value={ 40 } suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 col-span-1" 
            inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}} disabled/>
          </aside>
          
        </div>
      </section>
    </>
  )
}