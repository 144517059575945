import { useRef, useState } from 'react'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'primereact/tooltip'
import { FileUpload } from 'primereact/fileupload'
import { ProgressBar } from 'primereact/progressbar'
import { Button } from 'primereact/button'
import { Tag } from 'primereact/tag'
import { urlApi } from '../../helpers/helper'

export default function UploadPromo () {
  const [totalSize, setTotalSize] = useState(0)
  const toast = useRef(null)
  const fileUploadRef = useRef(null)

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize
    _totalSize += e.files[0].size
    setTotalSize(_totalSize)
  }

  const onTemplateUpload = (e) => {
    setTotalSize(e.files[0].size)
    toast.current.show({severity: 'success', summary: 'Success', detail: 'Archivo Cargado'})
  }

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size)
    callback()
  }

  const onTemplateClear = () => {
    setTotalSize(0)
  }

  const onErrorClear = (e) => {
    console.log(e)
    setTotalSize(0)
    toast.current.show({severity: 'error', summary: 'Error: Algo ha sucedido con el Archivo.', detail: 'Asegurese que el contenido del archivo, cumpla con el formato .csv requerido.'})
  }

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options
    const value = totalSize/10000
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B'

    return (
      <div className={className} style={{backgroundColor: 'var(--surface-b)', display: 'flex', alignItems: 'center'}}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{width: '300px', height: '20px', marginLeft: 'auto'}}></ProgressBar>
      </div>
    )
  }

  const itemTemplate = (file, props) => {
    return (
      <div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-2 sm:flex flex justify-items-center content-center gap-4">
        <div className='w-full grid content-center block'>
          <span  className='mx-4'>
              {file.name}
              <span className='px-5 sm:block block lg:inline md:inline'>{new Date().toLocaleDateString()}</span>
          </span>
        </div>
        <div className='w-full text-start block'>
          <Tag value={props.formatSize} severity="warning" className="p-tag-rounded" style={{padding: '0.8rem', margin: '0 0.5rem'}}/>
          <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger" tooltip="Eliminar Archivo"
          onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      </div>
    )
  }

  const emptyTemplate = () => {
    return (
      <div className='text-center'>
        <i className="pi pi-file mt-3 p-5" style={{'fontSize': '5em', color: 'var(--surface-d)'}}></i>
        <p style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">Arrastra el archivo en esta Sección</p>
      </div>
    )
  }

  const chooseOptions = {label: 'Archivo', icon: 'pi pi-fw pi-file', className: 'custom-choose-btn p-button-rounded p-button-outlined p-button-sm'}
  const uploadOptions = {label: 'Subir', icon: 'pi pi-fw pi-cloud-upload', className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined p-button-sm'}
  const cancelOptions = {label: 'Cancelar', icon: 'pi pi-fw pi-times', className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined p-button-sm'}

  return(
    <>
      <Toast ref={toast}></Toast>
      <Tooltip target=".custom-choose-btn" content="Seleccionar Archivo" position="bottom"/>
      <Tooltip target=".custom-upload-btn" content="Cargar Archivo" position="bottom" tooltipoptions={{showDelay: 1000, hideDelay: 300}} />
      <Tooltip target=".custom-cancel-btn" content="Eliminar Archivo" position="bottom" tooltipoptions={{showDelay: 1000, hideDelay: 300}}/>
      <h1 className='my-4 text-center text-3xl font-semibold text-gray-900'>Cargar Archivo Promociones</h1>
      <div className='grid justify-center my-4 w-full'>
        <a href= '/layoutPromociones.csv' download={`layoutPromociones.csv`} 
        className='text-center my-2 mx-20 px-20 py-2 text-white rounded-md bg-indigo-500 hover:bg-indigo-600'>Descargar Archivo Layout Promociones {'(.csv)'}</a>
      </div>
      <section className="mx-10 px-4 sm:px-2 lg:px-2 mb-10">
        <FileUpload ref={fileUploadRef} name="files[]" url={ `${urlApi}/post/promotion` } 
          maxFileSize={1000000} className='w-full' accept='.csv' onUpload={onTemplateUpload} onSelect={onTemplateSelect} 
          onError={onErrorClear} onClear={onTemplateClear} headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
          chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
      </section>
    </>
  )
}