import { useState, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { Link } from 'wouter'
import { useCotizacion } from '../../hooks/useCotizacion'
import { useLogin } from "../../hooks/useLogin"
import { ahorro_isr, iva_acreditable, costo_neto, renta_total } from '../../helpers/helper'
import Header from "../../components/Header"

// PrimeReact Styles
import "primereact/resources/themes/lara-light-indigo/theme.css"  //theme
import "primereact/resources/primereact.min.css"                  //core css
import "primeicons/primeicons.css"                                //icons

/*Pagina que muesta la cotizacion sin aportacion realizada*/
export default function CotizacionSA () {
  // const { valorFactura, rentaSA, residual, tipo, vehiculo, seguro, cotizacion, hibrido, importes } = useCotizacion()
 
  
  const { route } = useLogin()

  // Obtener los datos guardados del almacenamiento local
            const cotizacionData_valor_Factura = localStorage.getItem('valorFactura_data');
            const cotizacionData_valor_rentaSA = localStorage.getItem('rentaSA_data');
            const cotizacionData_valor_residual = localStorage.getItem('residual_data');
            const cotizacionData_valor_residualf5710 = localStorage.getItem('residualf5710_data');
            const cotizacionData_valor_tipo = localStorage.getItem('tipo_data');
            const cotizacionData_valor_vehiculo = localStorage.getItem('vehiculo_data');
            const cotizacionData_valor_seguro = localStorage.getItem('seguro_data');
            const cotizacionData_valor_cotizacion = localStorage.getItem('cotizacion_data');
            const cotizacionData_valor_hibrido = localStorage.getItem('hibrido_data');
            const cotizacionData_valor_importes = localStorage.getItem('importes_data');





            
            // console.log(cotizacionData)
            // Verificar si hay datos guardados
            // if (cotizacionData_valor_Factura) {
              // Convertir los datos de cadena JSON a objeto JavaScript
              const cotizacionDataObj_Factura = JSON.parse(cotizacionData_valor_Factura);
              const valorFactura = cotizacionDataObj_Factura ;
              const cotizacionDataObj_rentaSA = JSON.parse(cotizacionData_valor_rentaSA);
              const rentaSA = cotizacionDataObj_rentaSA
              const cotizacionDataObj_residual = JSON.parse(cotizacionData_valor_residual);
              const residual=cotizacionDataObj_residual
              
              const cotizacionDataObj_residualf7510 = JSON.parse(cotizacionData_valor_residualf5710);
              const residualf5710=cotizacionDataObj_residualf7510

              const cotizacionDataObj_tipo = JSON.parse(cotizacionData_valor_tipo);
              const tipo=cotizacionDataObj_tipo
              const cotizacionDataObj_vehiculo = JSON.parse(cotizacionData_valor_vehiculo);
              const vehiculo=cotizacionDataObj_vehiculo
              const cotizacionDataObj_seguro = JSON.parse(cotizacionData_valor_seguro);
              const seguro=cotizacionDataObj_seguro
              const cotizacionDataObj_cotizacion = JSON.parse(cotizacionData_valor_cotizacion);
              const cotizacion=cotizacionDataObj_cotizacion
              const cotizacionDataObj_hibrido = JSON.parse(cotizacionData_valor_hibrido);
              const hibrido =cotizacionDataObj_hibrido
              const cotizacionDataObj_importes = JSON.parse(cotizacionData_valor_importes);
              const importes  =cotizacionDataObj_importes
              
              const [ localizador ] = useState( [importes.localizador[0], importes.localizador[1], importes.localizador[2]] )
              // console.log("factura")
              // console.log(cotizacionDataObj_Factura)
              // console.log("rentaSa")
              // console.log(cotizacionDataObj_rentaSA)
              // console.log("residual7510")
              // console.log(residualf5710)
              // console.log("tipo")
              // console.log(cotizacionDataObj_tipo)
              // console.log("veiculo")
              // console.log(cotizacionDataObj_vehiculo)
              // console.log("seguro")
              // console.log(cotizacionDataObj_seguro)
              // console.log("cotizacion")
              // console.log(cotizacionDataObj_cotizacion)
              // console.log("hibrido")
              // console.log(cotizacionDataObj_hibrido)
              // console.log("importes")
              // console.log(cotizacionDataObj_importes)
              
            // }

            /**Tewrmina el recoger data del estorage */
              const componentRef = useRef(null)
              const handlePrint = useReactToPrint({
                content: () => componentRef.current,
                documentTitle: 'Cotizacion_sin_arrendamiento',
              })
  return(
    <>
      <Header />
      <section className="w-full h-screen grid justify-items-center">
        <div className="lg:w-3/5 md:w-5/6 sm:w-11/12 w-11/12 py-3 my-3">
          <div id="cotizacion" ref={componentRef}>
            <img src="/logomovex.svg" alt="LogoMovex" className="w-[12rem] p-5"/>
            <div className="text-center">
              <p>Calzada Zavaleta 1108, Santa Cruz Buenavista Norte Torre Alfa Piso 17</p>
              <p>Puebla, Puebla. C.P. 72150.   Tel: 222 949 22 98</p>
              <p className="text-blue-500">movex_atencionclientes@grupoexcelencia.mx</p>
            </div>
            <div className="mt-4 mx-5">
              <h3 className="font-semibold inline">Cotización de Arrendamiento Puro</h3>
              <p className="inline mx-10">{new Date().toLocaleDateString('es-MX', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</p>
              <p>Agencia: {`${cotizacion.marca_agente} ${cotizacion.agencia_agente}`}</p>
              <p>Nombre del Asesor: {`${cotizacion.nombre_agente} ${cotizacion.apellido_paterno_agente} ${cotizacion.apellido_materno_agente}`}</p>
              <p>Cliente: {cotizacion.nombre_cliente}</p>
            </div>
            <blockquote className="my-5 p-fluid">
              <div className="grid grid-cols-2 gap-2 md:w-1/2 sm:w-2/3 w-2/3 mx-5 mb-3">
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">Vehículo</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">Precio con IVA</p>
                <InputText disabled style={{fontSize: '0.85rem', padding: '.5rem 1rem', color: 'black', opacity: 1}} value={vehiculo} className="w-full disabled:text-black" />
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={valorFactura} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}} 
                className="w-full" inputClassName="disabled:text-black"/>
              </div>
              <div className="grid grid-cols-5 gap-x-2 gap-y-1 xl:w-4/5 lg:w-4/5 md:w-4/5 sm:w-full w-full mx-5 mb-3">
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center col-span-2">Renta</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">24 Meses</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">36 Meses</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">48 Meses</p>
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="Renta Mensual" className="disabled:text-black col-span-2"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={rentaSA[0]} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={rentaSA[1]} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={rentaSA[2]} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="I.V.A" className="disabled:text-black col-span-2"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={rentaSA[0] * 0.16} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={rentaSA[1] * 0.16} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={rentaSA[2] * 0.16} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="Renta Total" className="disabled:text-black col-span-2"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={ renta_total(rentaSA[0]) } disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={ renta_total(rentaSA[1]) } disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={ renta_total(rentaSA[2]) } disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
              </div>
              <div className="grid grid-cols-5 gap-x-2 gap-y-1 xl:w-4/5 lg:w-4/5 md:w-4/5 sm:w-full w-full mx-5 mb-3">
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center col-span-2">Pago Inicial</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">Monto</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">Monto</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">Monto</p>
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="Aportación Inicial" className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={0} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={0} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={0} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="Seguro por el primer año"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={seguro} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={seguro} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={seguro} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="Localizador"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={localizador[0]} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={localizador[1]} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={localizador[2]} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>

                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="Comisión por Apertura"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={importes.comision_apertura} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={importes.comision_apertura} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={importes.comision_apertura} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>

                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="Total Pago Inicial"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={parseFloat(seguro) + localizador[0] + importes.comision_apertura} disabled  inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', background: 'rgba(0, 120, 255, 0.4', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={parseFloat(seguro) + localizador[1] + importes.comision_apertura} disabled  inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', background: 'rgba(0, 120, 255, 0.4', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={parseFloat(seguro) + localizador[2] + importes.comision_apertura} disabled  inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', background: 'rgba(0, 120, 255, 0.4', opacity: 1}}  inputClassName="disabled:text-black"/>
              </div>
              <div className="grid grid-cols-5 gap-x-2 gap-y-1 xl:w-4/5 lg:w-4/5 md:w-4/5 sm:w-full w-full mx-5 mb-3">
                <p className="truncate bg-gray-200 px-2 py-1 rounded rounded-md text-center col-span-2">{`Valor Residual Con (I.V.A)`}</p>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={residual[0]+residualf5710[0]} disabled 
                inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', color: 'black', background: 'rgba(0, 120, 255, 0.4', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={residual[1]+residualf5710[1]} disabled 
                inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', color: 'black', background: 'rgba(0, 120, 255, 0.4', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={residual[2]+residualf5710[2]} disabled 
                inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', color: 'black', background: 'rgba(0, 120, 255, 0.4', opacity: 1}}  inputClassName="disabled:text-black"/>

                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="Desembolso Total" className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={renta_total(rentaSA[0]) * 24 + seguro + localizador[0] + importes.comision_apertura} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={renta_total(rentaSA[1]) * 36 + seguro + localizador[1] + importes.comision_apertura} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={renta_total(rentaSA[2]) * 48 + seguro + localizador[2] + importes.comision_apertura} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="Ahorro I.S.R"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={ahorro_isr(renta_total(rentaSA[0]) * 24 + seguro + localizador[0] + importes.comision_apertura, tipo, rentaSA[0], hibrido)} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={ahorro_isr(renta_total(rentaSA[1]) * 36 + seguro + localizador[1] + importes.comision_apertura, tipo, rentaSA[1], hibrido)} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={ahorro_isr(renta_total(rentaSA[2]) * 48 + seguro + localizador[2] + importes.comision_apertura, tipo, rentaSA[2], hibrido)} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="I.V.A Acreditable"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={iva_acreditable(renta_total(rentaSA[0]) * 24 + seguro + localizador[0] + importes.comision_apertura, tipo, rentaSA[0], hibrido)} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={iva_acreditable(renta_total(rentaSA[1]) * 36 + seguro + localizador[1] + importes.comision_apertura, tipo, rentaSA[1], hibrido)} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={iva_acreditable(renta_total(rentaSA[2]) * 48 + seguro + localizador[2] + importes.comision_apertura, tipo, rentaSA[2], hibrido)} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>

                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="Costo Neto"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(rentaSA[0])) * 24 + parseFloat(seguro) + localizador[0] + importes.comision_apertura, tipo, rentaSA[0], hibrido)} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(rentaSA[1])) * 36 + parseFloat(seguro) + localizador[1] + importes.comision_apertura, tipo, rentaSA[1], hibrido)} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(rentaSA[2])) * 48 + parseFloat(seguro) + localizador[2] + importes.comision_apertura, tipo, rentaSA[2], hibrido)} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>

                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="Costo Neto + Valor Residual"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(rentaSA[0]) * 24 + seguro + localizador[0] + importes.comision_apertura, tipo, rentaSA[0], hibrido) + (residual[0]+residualf5710[0])} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(rentaSA[1]) * 36 + seguro + localizador[1] + importes.comision_apertura, tipo, rentaSA[1], hibrido) + residual[1]+residualf5710[1]} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(rentaSA[2]) * 48 + seguro + localizador[2] + importes.comision_apertura, tipo, rentaSA[2], hibrido) + residual[2]+residualf5710[2]} disabled inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>

                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem'}} value="Costo Neto VS Precio de la Unidad"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(rentaSA[0]) * 24 + seguro + localizador[0] + importes.comision_apertura, tipo, rentaSA[0], hibrido) + (residual[0]+residualf5710[0]) - valorFactura} disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(rentaSA[1]) * 36 + seguro + localizador[1] + importes.comision_apertura, tipo, rentaSA[1], hibrido) + (residual[1]+residualf5710[1]) - valorFactura} disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(rentaSA[2]) * 48 + seguro + localizador[2] + importes.comision_apertura, tipo, rentaSA[2], hibrido) + (residual[2]+residualf5710[2]) - valorFactura} disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.5rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
              </div>
            </blockquote>
            <article className='mx-5 pr-5 text-[0.65rem]'>
              {`Compromiso en Movilidad Excelencia, S.A. de C.V. (MOVEX) `}
              <p className='text-justify'>
                Cotización sujeta a cambios sin previo aviso. Esta cotización representa un ejercicio aritmético en el cual se incluyen parámetros estándar definidos por MOVEX. 
                El cálculo del ahorro del I.S.R. y el I.V.A. acreditable son con fines informativos y estimados, 
                MOVEX calcula el ahorro del Impuesto Sobre la Renta conforme a la tasa prevista en el artículo 9 del Título II de las Personas Morales de la Ley del Impuesto Sobre la Renta y sobre la base que ocupa para la determinación del ahorro del I.S.R. 
                es la misma para aplicar la tasa del 16% prevista en el artículo 1 de la Ley del Impuesto al Valor Agregado. 
                Por lo anterior es que esta información puede variar dependiendo de las condiciones del Régimen en el que se encuentre. 
                Para determinar un importe exacto consulte al especialista en la materia de su preferencia. 
                Los limites previstos para el cálculo del ahorro del I.S.R. por concepto de Renta Mensual son los señalados en el artículo 28 fracción XIII de la Ley del Impuesto Sobre la Renta. 
                MOVEX otorga la opción de hacer deducible el gasto y acreditable el I.V.A. que corresponda a ese importe hasta por un importe igual a los limites previstos en el artículo 28 fracción XIII de la Ley del Impuesto Sobre la Renta, 
                siempre que se cumplan con las condiciones que MOVEX proporciona al cliente.
              </p>
            </article>
          </div>
        </div>

        <div className="w-3/5 mx-auto py-4 flex justify-center items-center">
        {/* <div className="w-3/5 mx-auto py-4 grid grid-cols-2 justify-items-stretch">
          <Link to={route} className="bg-sky-500 hover:bg-sky-600 py-1 px-2 text-white mb-4 w-2/4 rounded rounded-md text-center"><i className="pi pi-arrow-circle-left mr-2"></i>Regresar</Link> */}
          <button className="bg-blue-500 hover:bg-blue-600 py-1 px-2 text-white mb-4 w-2/4 rounded rounded-md justify-self-end" onClick={handlePrint}><i className="pi pi-print mr-2"></i>Imprimir</button>
        </div>
      </section>
    </>
  )
}