import { useEffect, useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
import { getConsultantUrl } from '../../helpers/helper'
import { InputText } from 'primereact/inputtext'

function ConsultantSelector ( {consultants, setConsultant} ) {
  return(
    <select id='consultantSelect' required className="relative block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 
      placeholder-gray-500 focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm" 
      onClick={(e) => {
        let asesor = consultants[e.target.value]
        setConsultant(asesor)
      }}>
        { consultants.map((el, index) => (<option key={el.id} value={index}>{`${el.nombres} ${el.apellido_paterno} ${el.apellido_materno}`}</option>)  ) }
    </select>
  )
}

export default function Consultants ( { setConsultant, consultant } ) {
  const { user } = useLogin()
  const [ consultants, setConsultants ] = useState([{id: user.id, marca: user.marca, agencia: user.agencia, nombres: user.nombres, 
    apellido_paterno: user.apellido_paterno, apellido_materno: user.apellido_materno}])
  
  useEffect(() => {
    fetch(getConsultantUrl)
      .then(res => res.json())
      .then(data => {
        data.unshift({id: user.id, marca: user.marca, agencia: user.agencia, nombres: user.nombres, 
          apellido_paterno: user.apellido_paterno, apellido_materno: user.apellido_materno})
        setConsultants(data)
      })
      // eslint-disable-next-line
  }, [])
  
  return (
    <>
      <section className='p-fluid grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-4'>
        <InputText placeholder="Distribuidor" value={`${consultant.marca} ${consultant.agencia}`} disabled style={{fontSize: '0.85rem', padding: '.5rem 1rem'}} className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm"/>
        <ConsultantSelector consultants={consultants} setConsultant={setConsultant} />
      </section>
    </>
  )
}