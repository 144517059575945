import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'
import { useState } from 'react'

import 'primereact/resources/themes/lara-light-blue/theme.css'
import 'primereact/resources/primereact.min.css'                  //core css
import 'primeicons/primeicons.css'

export default function AdminTable ( {data, setUpdateUser} ) {
  const [filters] = useState({
    'name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  })

  return (
    <>
      <section className='border border-slate-300 my-10'>
        <DataTable value={data} responsiveLayout="scroll" paginator filters={filters} filterDisplay="row"
         selectionMode="single" onSelectionChange={e => setUpdateUser(e.value)} style={{fontSize: '0.85rem'}}
         paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
         currentPageReportTemplate="Mostrando del {first} al {last} de un total de {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]}>
          <Column selectionMode="single" headerStyle={{width: '1rem'}} />
          <Column field='name' header='Nombre' sortable filter filterPlaceholder="Buscar por Nombre" showFilterMenu={false} />
          <Column field='email' header='Correo Electrónico' sortable />
          <Column field='marca' header='Marca' sortable />
          <Column field='agencia' header='Agencia' sortable />
          <Column field='telefono' header='Teléfono' sortable />
          <Column field='privilegios' header='Privilegios' sortable />
        </DataTable>
      </section>
    </>
  )
}