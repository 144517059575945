import { useState, useRef } from "react"
import { useReactToPrint } from 'react-to-print'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { Link } from 'wouter'
import { useCotizacion } from '../../hooks/useCotizacion'
import { useLogin } from "../../hooks/useLogin"
import { ahorro_isr, iva_acreditable, costo_neto, renta_total } from '../../helpers/helper'
import Header from '../../components/Header'

// PrimeReact Styles
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css"                  //core css
import "primeicons/primeicons.css"                                //icons

/*Pagina que muesta la cotizacion sin aportacion realizada*/
export default function Cotizacion () {
    //v1 misma pestaña de react
  // const { valorFactura, renta, residual2, aportacionInicial, tipo, vehiculo, seguro, cotizacion, hibrido, importes } = useCotizacion()
  // const [ localizador ] = useState( [importes.localizador[0], importes.localizador[1], importes.localizador[2]] )
  const { route } = useLogin()

  // const [ rentaGasto ] = useState([ 
  //   ((renta[0] > 6000 && renta[0] <= 12000 && hibrido === 'no') && ( (aportacionInicial[0]*100/valorFactura) >= 20 && (aportacionInicial[0]*100/valorFactura) <= 35 )) ? renta[0] 
  //   : ((renta[0] > 12000 && hibrido === 'no') && ( (aportacionInicial[0]*100/valorFactura) >= 20 && (aportacionInicial[0]*100/valorFactura) <= 35 )) ? 12000
  //   :((renta[0] > 8550 && renta[0] <= 17100 && hibrido === 'si') && ( (aportacionInicial[0]*100/valorFactura) >= 20 && (aportacionInicial[0]*100/valorFactura) <= 35 )) ? renta[0]
  //   : ((renta[0] > 17100 && hibrido === 'si') && ( (aportacionInicial[0]*100/valorFactura) >= 20 && (aportacionInicial[0]*100/valorFactura) <= 35 )) && 17100,

  //   ((renta[1] > 6000 && renta[1] <= 12000 && hibrido === 'no') && ( (aportacionInicial[1]*100/valorFactura) >= 20 && (aportacionInicial[1]*100/valorFactura) <= 35 )) ? renta[1] 
  //   : ((renta[1] > 12000 && hibrido === 'no') && ( (aportacionInicial[1]*100/valorFactura) >= 20 && (aportacionInicial[1]*100/valorFactura) <= 35 )) ? 12000
  //   :((renta[1] > 8550 && renta[1] <= 17100 && hibrido === 'si') && ( (aportacionInicial[1]*100/valorFactura) >= 20 && (aportacionInicial[1]*100/valorFactura) <= 35 )) ? renta[1]
  //   : ((renta[1] > 17100 && hibrido === 'si') && ( (aportacionInicial[1]*100/valorFactura) >= 20 && (aportacionInicial[1]*100/valorFactura) <= 35 )) && 17100,

  //   ((renta[2] > 6000 && renta[2] <= 12000 && hibrido === 'no') && ( (aportacionInicial[2]*100/valorFactura) >= 20 && (aportacionInicial[2]*100/valorFactura) <= 35 )) ? renta[2] 
  //   : ((renta[2] > 12000 && hibrido === 'no') && ( (aportacionInicial[2]*100/valorFactura) >= 20 && (aportacionInicial[2]*100/valorFactura) <= 35 )) ? 12000
  //   :((renta[2] > 8550 && renta[2] <= 17100 && hibrido === 'si') && ( (aportacionInicial[2]*100/valorFactura) >= 20 && (aportacionInicial[2]*100/valorFactura) <= 35 )) ? renta[2]
  //   : ((renta[2] > 17100 && hibrido === 'si') && ( (aportacionInicial[2]*100/valorFactura) >= 20 && (aportacionInicial[2]*100/valorFactura) <= 35 )) && 17100,
  // ])

  // const componentRef = useRef(null)
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: 'Cotizacion_con_arrendamiento',
  // })


 /**Data extraida del storage v2 para pasarlos a la segunda pagina con target */
////  const {  renta } = useCotizacion()
      const cotizacionData_valor_Factura = localStorage.getItem('valorFactura_data');
      const cotizacionData_valor_tipo = localStorage.getItem('tipo_data');
      const cotizacionData_valor_vehiculo = localStorage.getItem('vehiculo_data');
      const cotizacionData_valor_seguro = localStorage.getItem('seguro_data');
      const cotizacionData_valor_cotizacion = localStorage.getItem('cotizacion_data');
      const cotizacionData_valor_hibrido = localStorage.getItem('hibrido_data');
      const cotizacionData_valor_importes = localStorage.getItem('importes_data')

      const cotizacionData_valor_apotacionInicial = localStorage.getItem('apotacionInicial_data')
      const cotizacionData_valor_residual2 = localStorage.getItem('residual2_data')
      const cotizacionData_valor_residualf5710 = localStorage.getItem('residualf5710_data');

      const cotizacionData_valor_renta = localStorage.getItem('renta_data')


      const cotizacionDataObj_Factura = JSON.parse(cotizacionData_valor_Factura);
      const valorFactura = cotizacionDataObj_Factura ;
      const cotizacionDataObj_renta = JSON.parse(cotizacionData_valor_renta);
      // console.log("Renta")
      // console.log(cotizacionDataObj_renta)
      // console.log("rrrrrrrr");
      // console.log(renta);
      const renta = cotizacionDataObj_renta
      // console.log("renta");
      // console.log(renta);
      const cotizacionDataObj_residual2 = JSON.parse(cotizacionData_valor_residual2);
      const residual2=cotizacionDataObj_residual2
      // console.log("residual2");
      // console.log(residual2);

      const cotizacionDataObj_residualf7510 = JSON.parse(cotizacionData_valor_residualf5710);
      const residualf5710=cotizacionDataObj_residualf7510
      // console.log("residualf7510");
      // console.log(residualf5710);
      
      
      const cotizacionDataObj_apotacionInicial = JSON.parse(cotizacionData_valor_apotacionInicial);
      // console.log("API");
      // console.log(cotizacionDataObj_apotacionInicial)
      const aportacionInicial=cotizacionDataObj_apotacionInicial
      // console.log("aportacionInicial");
      // console.log(aportacionInicial);
      const cotizacionDataObj_tipo = JSON.parse(cotizacionData_valor_tipo);
      const tipo=cotizacionDataObj_tipo
      const cotizacionDataObj_vehiculo = JSON.parse(cotizacionData_valor_vehiculo);
      const vehiculo=cotizacionDataObj_vehiculo
      const cotizacionDataObj_seguro = JSON.parse(cotizacionData_valor_seguro);
      const seguro=cotizacionDataObj_seguro
      const cotizacionDataObj_cotizacion = JSON.parse(cotizacionData_valor_cotizacion);
      const cotizacion=cotizacionDataObj_cotizacion
      const cotizacionDataObj_hibrido = JSON.parse(cotizacionData_valor_hibrido);
      const hibrido =cotizacionDataObj_hibrido
      const cotizacionDataObj_importes = JSON.parse(cotizacionData_valor_importes);
      const importes  =cotizacionDataObj_importes 


//  /**Termina la extracion de datos del storage */

      const [ localizador ] = useState( [importes.localizador[0], importes.localizador[1], importes.localizador[2]] )

      const [ rentaGasto ] = useState([ 
        ((renta[0] > 6000 && renta[0] <= 12000 && hibrido === 'no') && ( (aportacionInicial[0]*100/valorFactura) >= 20 && (aportacionInicial[0]*100/valorFactura) <= 35 )) ? renta[0] 
        : ((renta[0] > 12000 && hibrido === 'no') && ( (aportacionInicial[0]*100/valorFactura) >= 20 && (aportacionInicial[0]*100/valorFactura) <= 35 )) ? 12000
        :((renta[0] > 8550 && renta[0] <= 17100 && hibrido === 'si') && ( (aportacionInicial[0]*100/valorFactura) >= 20 && (aportacionInicial[0]*100/valorFactura) <= 35 )) ? renta[0]
        : ((renta[0] > 17100 && hibrido === 'si') && ( (aportacionInicial[0]*100/valorFactura) >= 20 && (aportacionInicial[0]*100/valorFactura) <= 35 )) && 17100,

        ((renta[1] > 6000 && renta[1] <= 12000 && hibrido === 'no') && ( (aportacionInicial[1]*100/valorFactura) >= 20 && (aportacionInicial[1]*100/valorFactura) <= 35 )) ? renta[1] 
        : ((renta[1] > 12000 && hibrido === 'no') && ( (aportacionInicial[1]*100/valorFactura) >= 20 && (aportacionInicial[1]*100/valorFactura) <= 35 )) ? 12000
        :((renta[1] > 8550 && renta[1] <= 17100 && hibrido === 'si') && ( (aportacionInicial[1]*100/valorFactura) >= 20 && (aportacionInicial[1]*100/valorFactura) <= 35 )) ? renta[1]
        : ((renta[1] > 17100 && hibrido === 'si') && ( (aportacionInicial[1]*100/valorFactura) >= 20 && (aportacionInicial[1]*100/valorFactura) <= 35 )) && 17100,

        ((renta[2] > 6000 && renta[2] <= 12000 && hibrido === 'no') && ( (aportacionInicial[2]*100/valorFactura) >= 20 && (aportacionInicial[2]*100/valorFactura) <= 35 )) ? renta[2] 
        : ((renta[2] > 12000 && hibrido === 'no') && ( (aportacionInicial[2]*100/valorFactura) >= 20 && (aportacionInicial[2]*100/valorFactura) <= 35 )) ? 12000
        :((renta[2] > 8550 && renta[2] <= 17100 && hibrido === 'si') && ( (aportacionInicial[2]*100/valorFactura) >= 20 && (aportacionInicial[2]*100/valorFactura) <= 35 )) ? renta[2]
        : ((renta[2] > 17100 && hibrido === 'si') && ( (aportacionInicial[2]*100/valorFactura) >= 20 && (aportacionInicial[2]*100/valorFactura) <= 35 )) && 17100,
      ])

      const componentRef = useRef(null)
      const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Cotizacion_con_arrendamiento',
      })


  return(
    <>
      {/* <header className="px-5 py-4 bg-slate-200">
        <Link to='/'><img src="/logomovex.svg" alt="Movex Logo" className="h-[2.5rem]" /></Link>
      </header> */}
      <Header />
      <section className="w-full h-screen grid justify-items-center">
        <div className="lg:w-3/5 md:w-5/6 sm:w-11/12 w-11/12 py-3 my-3" id="cotizacion">
          <div id="cotizacion" ref={componentRef}>
            <img src="/logomovex.svg" alt="LogoMovex" className="w-[12rem] px-5 py-2"/>
            <div className="text-center">
              <p>Calzada Zavaleta 1108, Santa Cruz Buenavista Norte Torre Alfa Piso 17</p>
              <p>Puebla, Puebla. C.P. 72150.   Tel: 222 949 22 98</p>
              <p className="text-blue-500">movex_atencionclientes@grupoexcelencia.mx</p>
            </div>
            <div className="mt-4 mx-5">
              <h3 className="font-semibold inline">Cotización de Arrendamiento Puro</h3>
              <p className="inline mx-10">{new Date().toLocaleDateString('es-MX', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</p>
              <p>Agencia: {`${cotizacion.marca_agente} ${cotizacion.agencia_agente}`}</p>
              <p>Nombre del Asesor: {`${cotizacion.nombre_agente} ${cotizacion.apellido_paterno_agente} ${cotizacion.apellido_materno_agente}`}</p>
              <p>Cliente: {cotizacion.nombre_cliente}</p>
            </div>
            <blockquote className="my-4 p-fluid">
              <div className="grid grid-cols-2 gap-2 md:w-1/2 sm:w-2/3 w-2/3 mx-5 mb-3">
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">Vehículo</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">Precio con IVA</p>
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value={vehiculo} className="w-full disabled:text-black" />
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={valorFactura} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} 
                className="w-full" inputClassName="disabled:text-black"/>
              </div>
              <div className="grid grid-cols-5 gap-x-2 gap-y-1 xl:w-4/5 lg:w-4/5 md:w-4/5 sm:w-full w-full mx-5 mb-3">
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center col-span-2">Renta</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">24 Meses</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">36 Meses</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">48 Meses</p>
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Renta Mensual" className="disabled:text-black col-span-2"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={renta[0]} disabled inputStyle={{fontSize: '0.85rem', padding: '.3rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={renta[1]} disabled inputStyle={{fontSize: '0.85rem', padding: '.3rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={renta[2]} disabled inputStyle={{fontSize: '0.85rem', padding: '.3rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="I.V.A" className="disabled:text-black col-span-2"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={renta[0] * 0.16} disabled inputStyle={{fontSize: '0.85rem', padding: '.3rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={renta[1] * 0.16} disabled inputStyle={{fontSize: '0.85rem', padding: '.3rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={renta[2] * 0.16} disabled inputStyle={{fontSize: '0.85rem', padding: '.3rem 1rem', opacity: 1}}  inputClassName="disabled:text-black"/>
                
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Renta Total" className="disabled:text-black col-span-2"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={ renta_total(renta[0]) } disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={ renta_total(renta[1]) } disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
                <InputNumber placeholder='Valor de Factura' mode="currency" currency="USD" locale="en-US" value={ renta_total(renta[2]) } disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
              </div>
              <div className="grid grid-cols-5 gap-x-2 gap-y-1 xl:w-4/5 lg:w-4/5 md:w-4/5 sm:w-full w-full mx-5 mb-3">
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center col-span-2">Pago Inicial</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">Monto</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">Monto</p>
                <p className="bg-gray-200 px-2 py-1 rounded rounded-md text-center">Monto</p>
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Aportación Inicial" className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={parseFloat(aportacionInicial[0])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={parseFloat(aportacionInicial[1])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={parseFloat(aportacionInicial[2])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Seguro por el primer año"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={seguro} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={seguro} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={seguro} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Localizador"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={localizador[0]} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={localizador[1]} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={localizador[2]} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>

                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Comisión por Apertura"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={importes.comision_apertura} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={importes.comision_apertura} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={importes.comision_apertura} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>

                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Total Pago Inicial"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={parseFloat(seguro) + localizador[0] + importes.comision_apertura + parseFloat(aportacionInicial[0])} disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={parseFloat(seguro) + localizador[1] + importes.comision_apertura + parseFloat(aportacionInicial[1])} disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={parseFloat(seguro) + localizador[2] + importes.comision_apertura + parseFloat(aportacionInicial[2])} disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
              </div>
              <div className="grid grid-cols-5 gap-x-2 gap-y-1 xl:w-4/5 lg:w-4/5 md:w-4/5 sm:w-full w-full mx-5 mb-3">
                <p className="truncate bg-gray-200 px-2 py-1 rounded rounded-md text-center col-span-2">{`Valor Residual Con (I.V.A)`}</p>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={residual2[0]+residualf5710[0]} disabled 
                inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', color: 'black', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={residual2[1]} disabled 
                inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', color: 'black', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={residual2[2]} disabled 
                inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', color: 'black', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>

                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Desembolso Total" className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={renta_total(parseFloat(renta[0])) * 24 + parseFloat(seguro) + localizador[0] + importes.comision_apertura + parseFloat(aportacionInicial[0])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={renta_total(parseFloat(renta[1])) * 36 + parseFloat(seguro) + localizador[1] + importes.comision_apertura + parseFloat(aportacionInicial[1])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={renta_total(parseFloat(renta[2])) * 48 + parseFloat(seguro) + localizador[2] + importes.comision_apertura + parseFloat(aportacionInicial[2])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Ahorro I.S.R"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={ahorro_isr(renta_total(parseFloat(renta[0])) * 24 + parseFloat(seguro) + localizador[0] + importes.comision_apertura + parseFloat(aportacionInicial[0]), tipo, renta[0], hibrido)} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={ahorro_isr(renta_total(parseFloat(renta[1])) * 36 + parseFloat(seguro) + localizador[1] + importes.comision_apertura + parseFloat(aportacionInicial[1]), tipo, renta[1], hibrido)} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={ahorro_isr(renta_total(parseFloat(renta[2])) * 48 + parseFloat(seguro) + localizador[2] + importes.comision_apertura + parseFloat(aportacionInicial[2]), tipo, renta[2], hibrido)} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                
                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="I.V.A Acreditable"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={iva_acreditable(renta_total(parseFloat(renta[0])) * 24 + parseFloat(seguro) + localizador[0] + importes.comision_apertura + parseFloat(aportacionInicial[0]), tipo, renta[0], hibrido)} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={iva_acreditable(renta_total(parseFloat(renta[1])) * 36 + parseFloat(seguro) + localizador[1] + importes.comision_apertura + parseFloat(aportacionInicial[1]), tipo, renta[1], hibrido)} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={iva_acreditable(renta_total(parseFloat(renta[2])) * 48 + parseFloat(seguro) + localizador[2] + importes.comision_apertura + parseFloat(aportacionInicial[2]), tipo, renta[2], hibrido)} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>

                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Costo Neto"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(renta[0])) * 24 + parseFloat(seguro) + parseFloat(localizador[0]) + importes.comision_apertura + parseFloat(aportacionInicial[0]), tipo, renta[0], hibrido )} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(renta[1])) * 36 + parseFloat(seguro) + parseFloat(localizador[1]) + importes.comision_apertura + parseFloat(aportacionInicial[1]), tipo, renta[1], hibrido )} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(renta[2])) * 48 + parseFloat(seguro) + parseFloat(localizador[2]) + importes.comision_apertura + parseFloat(aportacionInicial[2]), tipo, renta[2], hibrido )} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>

                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Costo Neto + Valor Residual"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(renta[0])) * 24 + parseFloat(seguro) + parseFloat(localizador[0]) + importes.comision_apertura + parseFloat(aportacionInicial[0]), tipo, renta[0], hibrido) + (residual2[0]+residualf5710[0])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(renta[1])) * 36 + parseFloat(seguro) + parseFloat(localizador[1]) + importes.comision_apertura + parseFloat(aportacionInicial[1]), tipo, renta[1], hibrido) + (residual2[1])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(renta[2])) * 48 + parseFloat(seguro) + parseFloat(localizador[2]) + importes.comision_apertura + parseFloat(aportacionInicial[2]), tipo, renta[2], hibrido) + (residual2[2])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/>


                <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Costo Neto VS Precio de la Unidad"  className="disabled:text-black col-span-2"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(renta[0])) * 24 + parseFloat(seguro) + parseFloat(localizador[0]) + importes.comision_apertura + parseFloat(aportacionInicial[0]), tipo, renta[0], hibrido) + (residual2[0]+residualf5710[0]) - valorFactura} disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(renta[1])) * 36 + parseFloat(seguro) + parseFloat(localizador[1]) + importes.comision_apertura + parseFloat(aportacionInicial[1]), tipo, renta[1], hibrido) + (residual2[1]) - valorFactura} disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
                <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(renta[2])) * 48 + parseFloat(seguro) + parseFloat(localizador[2]) + importes.comision_apertura + parseFloat(aportacionInicial[2]), tipo, renta[2], hibrido) + (residual2[2]) - valorFactura} disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/>
              </div>

              {/* Deduccion al Gasto */}
              {((rentaGasto[0] !== false || rentaGasto[1] !== false || rentaGasto[2] !== false) && tipo === 'particular') &&
                <div className="grid grid-cols-5 gap-x-2 gap-y-1 xl:w-4/5 lg:w-4/5 md:w-4/5 sm:w-full w-full mx-5 mb-3">
                  <p className="bg-gray-200 col-span-5 py-1 rounded rounded-md mb-2 text-sm px-5">Cálculo aplicado con la opción de <em>"gasto"</em>.</p>              
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Ahorro I.S.R"  className="disabled:text-black col-span-2"/>
                  { rentaGasto[0] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={ahorro_isr(renta_total(parseFloat(rentaGasto[0])) * 24 + parseFloat(seguro) + localizador[0] + importes.comision_apertura + parseFloat(aportacionInicial[0]), 'gasto', renta[0], hibrido)} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  { rentaGasto[1] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={ahorro_isr(renta_total(parseFloat(rentaGasto[1])) * 36 + parseFloat(seguro) + localizador[1] + importes.comision_apertura + parseFloat(aportacionInicial[1]), 'gasto', renta[1], hibrido)} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  { rentaGasto[2] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={ahorro_isr(renta_total(parseFloat(rentaGasto[2])) * 48 + parseFloat(seguro) + localizador[2] + importes.comision_apertura + parseFloat(aportacionInicial[2]), 'gasto', renta[2], hibrido)} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  
                  {/* IVA Acreditable */}
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="I.V.A Acreditable"  className="disabled:text-black col-span-2"/>
                  { rentaGasto[0] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={iva_acreditable(renta_total(parseFloat(rentaGasto[0])) * 24 + parseFloat(seguro) + localizador[0] + importes.comision_apertura + parseFloat(aportacionInicial[0]), 'gasto', renta[0], hibrido)} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  { rentaGasto[1] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={iva_acreditable(renta_total(parseFloat(rentaGasto[1])) * 36 + parseFloat(seguro) + localizador[1] + importes.comision_apertura + parseFloat(aportacionInicial[1]), 'gasto', renta[1], hibrido)} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  { rentaGasto[2] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={iva_acreditable(renta_total(parseFloat(rentaGasto[2])) * 48 + parseFloat(seguro) + localizador[2] + importes.comision_apertura + parseFloat(aportacionInicial[2]), 'gasto', renta[2], hibrido)} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  
                  {/* Costo Neto */}
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Costo Neto"  className="disabled:text-black col-span-2"/>
                  { rentaGasto[0] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(rentaGasto[0])) * 24 + parseFloat(seguro) + localizador[0] + importes.comision_apertura + parseFloat(aportacionInicial[0]), 'gasto', renta[0])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  { rentaGasto[1] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(rentaGasto[1])) * 36 + parseFloat(seguro) + localizador[1] + importes.comision_apertura + parseFloat(aportacionInicial[1]), 'gasto', renta[1])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  { rentaGasto[2] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(rentaGasto[2])) * 48 + parseFloat(seguro) + localizador[2] + importes.comision_apertura + parseFloat(aportacionInicial[2]), 'gasto', renta[2])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  
                  {/* Costo Neto + Valor Residual */}
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Costo Neto + Valor Residual"  className="disabled:text-black col-span-2"/>
                  { rentaGasto[0] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(rentaGasto[0])) * 24 + parseFloat(seguro) + localizador[0] + importes.comision_apertura + parseFloat(aportacionInicial[0]), 'gasto', renta[0]) + (residual2[0]+residualf5710[0])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  { rentaGasto[1] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(rentaGasto[1])) * 36 + parseFloat(seguro) + localizador[1] + importes.comision_apertura + parseFloat(aportacionInicial[1]), 'gasto', renta[1]) + (residual2[1])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  { rentaGasto[2] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(rentaGasto[2])) * 48 + parseFloat(seguro) + localizador[2] + importes.comision_apertura + parseFloat(aportacionInicial[2]), 'gasto', renta[2]) + (residual2[2])} disabled inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }

                  {/* Costo Neto vs Precio de la Unidad */}
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="Costo Neto VS Precio de la Unidad"  className="disabled:text-black col-span-2"/>
                  { rentaGasto[0] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(rentaGasto[0])) * 24 + parseFloat(seguro) + localizador[0] + importes.comision_apertura + parseFloat(aportacionInicial[0]), 'gasto', renta[0]) + (residual2[0]+residualf5710[0]) - valorFactura} disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  { rentaGasto[1] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(rentaGasto[1])) * 36 + parseFloat(seguro) + localizador[1] + importes.comision_apertura + parseFloat(aportacionInicial[1]), 'gasto', renta[1]) + (residual2[1]) - valorFactura} disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                  { rentaGasto[2] !== false ? 
                  <InputNumber mode="currency" currency="USD" locale="en-US" value={costo_neto(renta_total(parseFloat(rentaGasto[2])) * 48 + parseFloat(seguro) + localizador[2] + importes.comision_apertura + parseFloat(aportacionInicial[2]), 'gasto', renta[2]) + (residual2[2]) - valorFactura} disabled  inputStyle={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem', background: 'rgba(0, 120, 255, 0.4'}}  inputClassName="disabled:text-black"/> : 
                  <InputText disabled style={{opacity: 1, fontSize: '0.85rem', padding: '.3rem 1rem'}} value="-"  className="disabled:text-black"/> }
                </div>
              }
            </blockquote>
            <article className='mx-5 pr-5 text-[0.65rem]'>
              {`Compromiso en Movilidad Excelencia, S.A. de C.V. (MOVEX) `}
              <p className='text-justify'>
                Cotización sujeta a cambios sin previo aviso. Esta cotización representa un ejercicio aritmético en el cual se incluyen parámetros estándar definidos por MOVEX. 
                El cálculo del ahorro del I.S.R. y el I.V.A. acreditable son con fines informativos y estimados, 
                MOVEX calcula el ahorro del Impuesto Sobre la Renta conforme a la tasa prevista en el artículo 9 del Título II de las Personas Morales de la Ley del Impuesto Sobre la Renta y sobre la base que ocupa para la determinación del ahorro del I.S.R. 
                es la misma para aplicar la tasa del 16% prevista en el artículo 1 de la Ley del Impuesto al Valor Agregado. 
                Por lo anterior es que esta información puede variar dependiendo de las condiciones del Régimen en el que se encuentre. 
                Para determinar un importe exacto consulte al especialista en la materia de su preferencia. 
                Los limites previstos para el cálculo del ahorro del I.S.R. por concepto de Renta Mensual son los señalados en el artículo 28 fracción XIII de la Ley del Impuesto Sobre la Renta. 
                MOVEX otorga la opción de hacer deducible el gasto y acreditable el I.V.A. que corresponda a ese importe hasta por un importe igual a los limites previstos en el artículo 28 fracción XIII de la Ley del Impuesto Sobre la Renta, 
                siempre que se cumplan con las condiciones que MOVEX proporciona al cliente.
              </p>
            </article>
          </div>
        </div>

        <div className="w-3/5 mx-auto py-4 flex justify-center items-center">
        {/* <div className="w-3/5 mx-auto py-4 grid grid-cols-2 justify-items-stretch">
          <Link to={route} className="bg-sky-500 hover:bg-sky-600 py-1 px-2 text-white mb-4 w-2/4 rounded rounded-md text-center"><i className="pi pi-arrow-circle-left mr-2"></i>Regresar</Link> */}
          <button className="bg-blue-500 hover:bg-blue-600 py-1 px-2 text-white mb-4 w-2/4 rounded rounded-md justify-self-end" onClick={handlePrint}><i className="pi pi-print mr-2"></i>Imprimir</button>
        </div>
      </section>
    </>
  )
}