import { useState, useEffect, useRef } from 'react'
import { urlServer, urlApi } from "../../helpers/helper"
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'

export default function Documents ( {trigger, setTrigger} ) {
  const [ documents, setDocuments ] = useState([])
  const toast = useRef(null)
  const confirmToast = useRef(null)

  useEffect(() => {
    if(trigger){
      fetch(`${urlServer}/api/get/document`)
        .then(res => res.json())
        .then(data => {
          let document = [], file = []
          if(data.length > 0){
            data.map(el => {
              file = el.split('.')
              //split para cervidor, comentar en local
              let urlB = urlServer
              let newURLStorage=urlB.replace('public/index.php','storage/app')
              document.push({name: file[0].split('-')[0],tipo:file[0].split('-')[1] ,url: `${newURLStorage}/documents/${el}`, ext: file[1]})


              //termnia parte de configuracion del servidor para ver los archivos

              //local
              // console.log(`aca url ${urlServer}/documents/${el}`)
              // console.log(urlServer)
              // console.log(el)
              // document.push({name: file[0].split('-')[0],tipo:file[0].split('-')[1] ,url: `${urlServer}/documents/${el}`, ext: file[1]})
              //termina local
              return null
            })
          }
          setDocuments(document)
          setTrigger(false)
        })
    }
  }, [trigger, setTrigger])

  const showToast = ( severity, summary, detail ) => {
    toast.current.show({severity: severity, summary: summary, detail:detail, life: 3000})
  }

  const showConfirm = ( rowData ) => {
    confirmToast.current.show({severity: 'info', sticky: true, content: (
      <div className='w-full mx-auto px-4 py-2 text-center'>
          <i className="pi pi-exclamation-triangle mt-2 mb-4" style={{fontSize: '3rem'}}></i>
          <h2>¿Desea eliminar este archivo?</h2>
          <section className='mx-auto grid grid-cols-2 gap-4 py-4'>
            {/* {console.log(`/delete/document/${rowData.name}-${rowData.tipo}.${rowData.ext}`)} */}
            <button className='border rounded-lg bg-emerald-400 py-2 text-white hover:bg-emerald-500' onClick={() => {
              fetch(`${urlApi}/delete/document/${rowData.name}-${rowData.tipo}.${rowData.ext}`)
                .then(res => res.json())
                .then(result => {
                  result.message === 'success' ? showToast('success', 'Success', 'El archivo fue eliminado correctamente') 
                  : showToast('error', 'Error', 'El archivo no pudo ser eliminado, intentalo más tarde.') 
                  setTrigger(true)
                })
                confirmToast.current.clear()
            }}>Si</button>
            <button className='border rounded-lg border-red-400 text-red-400 border-2 hover:bg-red-100' onClick={() => confirmToast.current.clear()}>No</button>
          </section>
      </div>
    )})
  }

  const itemBodyTemplate = (rowData) => {
    return <a href={rowData.url} target='_blank' rel="noreferrer" className='text-blue-500 hover:text-blue-600'><i className='pi pi-file mr-2 ml-5'></i>{rowData.name}</a>
  }

  const deleteBodyTemplate = (rowData) => {
    return <button className='py-1 px-2 border border-red-500 rounded-lg text-white bg-red-500 hover:bg-red-600' onClick={() => {
      showConfirm(rowData)
    }}>Borrar Archivo</button>
  }

  return (
    <>
      <Toast ref={toast} />
      <Toast ref={confirmToast} position='center'/>
      <div className='w-full border border-gray-200'>
        <DataTable value={documents} size='small' emptyMessage="No hay documentos que mostrar" header='Documentos' responsiveLayout="scroll">
          <Column field='name' body={itemBodyTemplate} headerStyle={{display: 'none'}} />
          <Column bodyStyle={{ textAlign: 'center' }} body={deleteBodyTemplate} headerStyle={{display: 'none'}}></Column>
        </DataTable>
      </div>
    </>
  )
}