import { useState, useEffect } from 'react'
import { Chart } from 'primereact/chart'

export default function AsesoresChart ( { data } ) {
  const [ chartData, setChartData ] = useState({})
  const [ color ] = useState(['#22c55e', '#14b8a6', '#06b6d4', '#3b82f6', '#8b5cf6', '#c026d3', '#dc2626', '#ea580c', '#f59e0b', '#84cc16'])

  const [chartOptions] = useState({
    maintainAspectRatio: false,
      aspectRatio: .8,
      plugins: {
          legend: {
              labels: {
                  color: '#495057'
              }
          }
      },
      scales: {
          x: {
              ticks: {
                  color: '#495057'
              },
              grid: {
                  color: '#ebedef'
              }
          },
          y: {
              ticks: {
                  color: '#495057'
              },
              grid: {
                  color: '#ebedef'
              }
          }
      }
  })

  useEffect(() => {
    let label = []
    let cotizaciones = []
    let colores = []
    data.map((el, index) => {
      label.push(`${el.nombre_agente} ${el.apellido_paterno_agente} ${el.apellido_materno_agente}`)
      cotizaciones.push(el.cotizaciones)
      colores.push(color[index])
      return null
    })
    setChartData({labels: label, datasets:[{label: 'Cotizaciones', backgroundColor: colores, data: cotizaciones}] })
  }, [data, color])

  return(
    <>
      <div className='lg:m-5 md:m-4 sm:m-1 m-1'>
        <Chart type="bar" data={chartData} options={chartOptions} />
      </div>
    </>
  )
}