import { useContext } from 'react'
import CotizacionContext from '../context/CotizacionContext'

//Se encargara de actualizar el estado para los datos realcionados al usuario
export const useCotizacion = () => {
  const {valorFactura, setValorFactura, renta, setRenta, rentaSA, setRentaSA, residual, setResidual, aportacionInicial, setAportacionInicial, tipo, setTipo, 
    vehiculo, setVehiculo, seguro, setSeguro, residual2, setResidual2,residualf5710, setResidualf5710, cotizacion, setCotizacion, hibrido, setHibrido, parametros, importes, setImportes } = useContext(CotizacionContext)

  return { valorFactura, setValorFactura, renta, setRenta, rentaSA, setRentaSA, residual, setResidual, aportacionInicial, setAportacionInicial, tipo, setTipo, 
    vehiculo, setVehiculo, seguro, setSeguro, residual2, setResidual2,residualf5710, setResidualf5710, cotizacion, setCotizacion, hibrido, setHibrido, parametros, importes, setImportes }
}