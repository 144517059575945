import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { Button } from 'primereact/button'
import { FilterMatchMode } from 'primereact/api'
import { useState } from 'react'
import { useEffect } from 'react'
import { getInsuranceUrl } from '../../helpers/helper'
import { addLocale } from 'primereact/api'

import 'primereact/resources/themes/lara-light-blue/theme.css'
import 'primereact/resources/primereact.min.css'                  //core css
import 'primeicons/primeicons.css'

export default function InsuranceTable () {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [printData, setPrintData] = useState([])
  const [brands, setBrands] = useState([])
  const [years, setYears] = useState([])
  const [models, setModels] = useState([])
  const [aseguradora, setAseguradora] = useState([])

  const [filters] = useState({
    'anio_auto': { value: null, matchMode: FilterMatchMode.IN },
    'modelo_auto': { value: null, matchMode: FilterMatchMode.IN },
    'marca_auto': { value: null, matchMode: FilterMatchMode.IN },
    'aseguradora': { value: null, matchMode: FilterMatchMode.EQUALS },
    'vigencia_inicio': { value: null, matchMode: FilterMatchMode.DATE_AFTER },
    'vigencia_final': { value: null, matchMode: FilterMatchMode.DATE_BEFORE }
  })
  
  useEffect( () => {
    setLoading(true)
    fetch(`${getInsuranceUrl}/date`)
      .then(res => res.json())
      .then(result => {
        let marcas = []
        let modelos = []
        let years = []
        let aseguradoras = []
        let printToData = []
        result.map(el => {
          el.vigencia_inicio = new Date(el.vigencia_inicio)
          el.vigencia_final = new Date(el.vigencia_final)
          el.precio = el.precio.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
          marcas.push(el.marca_auto)
          modelos.push(el.modelo_auto)
          years.push(el.anio_auto)
          aseguradoras.push(el.aseguradora)
          printToData.push( {Marca_del_vehiculo: el.marca_auto, Modelo_del_vehiculo: el.modelo_auto, Anio_del_vehiculo: el.anio_auto, Aseguradora: el.aseguradora, 
          Precio_del_seguro: el.precio, Vigencia_inicial: el.vigencia_inicio, Vigencia_final: el.vigencia_final} )
          return null
        })
        setBrands([...new Set(marcas)])
        setModels([...new Set(modelos)])
        setYears([...new Set(years)])
        setAseguradora([...new Set(aseguradoras)])
        setData(result)
        setPrintData(printToData)
      })
    setLoading(false)
  }, [])

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar'
  })

  //Template 
  const priceBodyTemplate = (rowData) => {
    const priceFormat = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' })
    return priceFormat.format(rowData.precio)
  }

  const dateBodyTemplate = (rowData) => {
    const date = new Date(rowData.vigencia_inicio)
    return date.toLocaleDateString('es-MX', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }) 
  }

  const dateBodyTemplate2 = (rowData) => {
    const date = new Date(rowData.vigencia_final)
    return date.toLocaleDateString('es-MX', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }) 
  }

  // Flitros
  const brandRowFilterTemplate = (options) => {
    return <MultiSelect value={options.value} options={brands} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Marca del Vehículo"
    className="p-multiselect w-[10rem] text-sm" panelStyle={{fontSize: '0.85rem'}} maxSelectedLabels={1} style={{height: '2rem', alignItems: 'center'}}/>
  }

  const modelRowFilterTemplate = (options) => {
    return <MultiSelect value={options.value} options={models} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Modelo del Vehículo"
    className="p-multiselect w-[11rem] text-sm" panelStyle={{fontSize: '0.85rem'}} maxSelectedLabels={1} style={{height: '2rem', alignItems: 'center'}}/>
  }

  const anioRowFilterTemplate = (options) => {
    return <MultiSelect value={options.value} options={years} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Año del Vehículo"
    className="p-multiselect w-[10rem] text-sm" panelStyle={{fontSize: '0.85rem'}} maxSelectedLabels={1} style={{height: '2rem', alignItems: 'center'}}/>
  }

  const aseguradoraRowFilterTemplate = (options) => {
    return <Dropdown value={options.value} options={aseguradora} onChange={(e) => options.filterApplyCallback(e.value)} 
    placeholder="Aseguradora" style={{height: '2rem', alignItems: 'center'}} className="p-column-filter w-[9rem] text-sm" panelStyle={{fontSize: '0.85rem'}} showClear />
  }
  
  const dateRowFilterTemplate = (options) => {
    return <Calendar value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} dateFormat="dd/mm/yy" placeholder="dd/mm/aaaa" mask="99/99/9999"
    className='w-[8rem] text-sm' inputStyle={{height: '2rem', alignItems: 'center'}} locale='es'/>
  }
  
  const dateRowFilterTemplate2 = (options) => {
    return <Calendar value={options.value} onChange={(e) => options.filterApplyCallback(new Date(e.value.setHours(23)))} dateFormat="dd/mm/yy" placeholder="dd/mm/aaaa" mask="99/99/9999"
    className='w-[8rem] text-sm' inputStyle={{height: '2rem', alignItems: 'center'}} locale='es' />
  }


  //Export File
  const exportExcel = () => {
    import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(printData)
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] }
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' })
        saveAsExcelFile(excelBuffer, 'seguros')
    })
  }

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(module => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            let EXCEL_EXTENSION = '.xlsx'
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
        }
    })
  }

  const header = (
    <section className='grid grid-cols-12'>
      <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" tooltip="Exportar a Excel" />
      <div className='table-header text-center col-span-11 text-3xl self-center font-semibold'>Seguros</div>
    </section>
  )

  return (
    <>
      { loading ? <div>Loading...</div> : 
        <section className='mx-10 border border-slate-300 my-20'>
          <DataTable value={data} responsiveLayout="scroll" header={header} paginator filters={filters} filterDisplay="row" removableSort
           paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
           currentPageReportTemplate="Mostrando del {first} al {last} de un total de {totalRecords} registros" rows={10} rowsPerPageOptions={[10,20,50]} style={{fontSize: '0.85rem'}}>
            <Column field='marca_auto' header='Marca del vehículo' sortable showFilterMenu={false} filter filterElement={brandRowFilterTemplate} />
            <Column field='modelo_auto' header='Modelo del vehículo' sortable showFilterMenu={false} filter filterElement={modelRowFilterTemplate} />
            <Column field='anio_auto' header='Año del vehículo' sortable showFilterMenu={false} filter filterElement={anioRowFilterTemplate}/>
            <Column field='aseguradora' header='Aseguradora' sortable showFilterMenu={false} filter filterElement={aseguradoraRowFilterTemplate}/>
            <Column header='Precio del seguro' body={priceBodyTemplate} field='precio' sortable />
            <Column header='Fecha Inicio' body={dateBodyTemplate} field='vigencia_inicio' dataType='date' filter filterElement={dateRowFilterTemplate} sortable showFilterMenu={false}/>
            <Column header='Fecha Fin' body={dateBodyTemplate2} field='vigencia_final' dataType='date' filter filterElement={dateRowFilterTemplate2} sortable showFilterMenu={false} />
          </DataTable>
        </section>
      }
    </>
  )
}