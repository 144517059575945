import './App.css'
import { useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'wouter'
import Login from './pages/Login/index'
import Logout from './pages/Logout/index'
import Supervisor from './pages/Supervisor/index'
import Vendedor from './pages/Vendedor/index'
import Admin from './pages/Admin/index'
import Broker from './pages/Broker/index'
import Cotizacion from './pages/Cotizacion/index'
import CotizacionSA from './pages/CotizacionSA'
import Comparative from './pages/Comparative'
import Notfound from './pages/Notfound'
import { LoginContextProvider } from './context/LoginContext'
import { CotizacionContextProvider } from './context/CotizacionContext'
import { sessionUrl } from './helpers/helper'

export default function App() {
  const [ isLogged, setIsLogged ] = useState(JSON.parse(localStorage.getItem('user')) !== null ? JSON.parse(localStorage.getItem('user')) : {})
  useEffect(() => {
    if( Object.keys(isLogged).length > 0 ){
      fetch(`${sessionUrl}/${isLogged.id}`)
        .then(res => res.json())
        .then(response => {
          if( response.session === 0){
            setIsLogged({})
            localStorage.clear()
          }
        })
    }
  }, [isLogged])

  return (
    <div>
      <LoginContextProvider>
        <CotizacionContextProvider>
          <Switch>
            <Route path='/login'><Login setIsLogged={setIsLogged} isLogged={isLogged} /></Route>
            <Route path='/logout'><Logout setIsLogged={setIsLogged} /></Route>
            <Route path='/'>
              { isLogged.tipo === 'administrador' ? <Redirect to='/admin' /> :
                isLogged.tipo === 'vendedor' ? <Redirect to='/vendedor' /> :
                isLogged.tipo === 'supervisor' ? <Redirect to='/supervisor' /> :
                isLogged.tipo === 'broker' ? <Redirect to='/broker' /> :
                <Redirect to='/login' /> }
            </Route>
            <Route path='/supervisor'>{ isLogged.tipo === 'supervisor' ? <Supervisor isLogged={isLogged}/> : <Redirect to='/login' /> }</Route> 
            <Route path='/vendedor'>{ isLogged.tipo === 'vendedor' ? <Vendedor isLogged={isLogged}/> : <Redirect to='/login' /> }</Route> 
            <Route path='/admin'>{ isLogged.tipo === 'administrador' ? <Admin isLogged={isLogged}/> : <Redirect to='/login' /> }</Route> 
            <Route path='/broker'>{ isLogged.tipo === 'broker' ? <Broker isLogged={isLogged}/> : <Redirect to='/login' /> }</Route> 
            <Route path='/cotizacion'>{ Object.keys(isLogged).length > 0 ? <Cotizacion isLogged={isLogged}/> : <Redirect to='/login' /> }</Route> 
            <Route path='/cotizacionSA'>{ Object.keys(isLogged).length > 0 ? <CotizacionSA isLogged={isLogged}/> : <Redirect to='/login' /> }</Route>
            <Route path='/comparative'>{ Object.keys(isLogged).length > 0 ? <Comparative isLogged={isLogged}/> : <Redirect to='/login' /> }</Route>
            <Route path="/:rest*" component={Notfound} />
          </Switch>
        </CotizacionContextProvider>
      </LoginContextProvider>
    </div>
  )
}
