import nr from 'newton-raphson-method'

//local
// // // // const api = 'http://192.168.0.186:8000/api'
// // // // export const urlServer = 'http://192.168.0.186:8000'

// const api = 'http://127.0.0.1:8000/api'
// export const urlServer = 'http://127.0.0.1:8000'
//produccion
   const api = 'https://cotizador.movex.mx/backend/public/index.php/api'
   export const urlServer = 'https://cotizador.movex.mx/backend/public/index.php'

export const loginUrl = `${api}/login/user`
export const sessionUrl = `${api}/get/session`
export const addUserUrl = `${api}/post/user`
export const putUserUrl = `${api}/put/user`
export const getCarUrl = `${api}/get/car/date`
export const getAgency = `${api}/get/agency`
export const getInsuranceUrl = `${api}/get/insurance`
export const getInsuranceIdUrl = `${api}/get/insurance`
export const postCotizacionUrl = `${api}/post/cotizacion`
export const getCotizacionUrl = `${api}/get/cotizacion`
export const putCarUrl = `${api}/put/car`
export const putPromoUrl = `${api}/put/promotion`
export const getPromoUrl = `${api}/get/promotion`
export const getParametersUrl = `${api}/get/parameters`
export const putParametersUrl = `${api}/put/parameters`
export const getConsultantUrl = `${api}/get/consultant`
export const version_Movex = `Cotizador Movex v.2.0 by BMTools`
export const urlApi = api

export const login = [
  {
    email: 'supervisor@movex.mx',
    name: 'Jon Doe',
    type: 'supervisor',
    password: 'password'
  },
  {
    email: 'vendedor@movex.mx',
    name: 'Juan Perez',
    type: 'vendedor',
    password: 'password'
  },
  {
    email: 'admin@movex.mx',
    name: 'Morgan Freeman',
    type: 'administrador',
    password: 'password'
  },
  {
    email: 'broker@movex.mx',
    name: 'Marina Rios',
    type: 'broker',
    password: 'password'
  }
]

export const users = [
  {
    id: 1,
    email: 'supervisor@movex.mx',
    name: 'Jon Doe',
    password: 'password',
    status: 'activo'
  },
  {
    id: 2,
    email: 'supervisor@movex.mx',
    name: 'Jon Doe',
    password: 'password',
    status: 'activo'
  },
  {
    id: 3,
    email: 'supervisor@movex.mx',
    name: 'Jon Doe',
    password: 'password',
    status: 'activo'
  },
  {
    id: 4,
    email: 'supervisor@movex.mx',
    name: 'Jon Doe',
    password: 'password',
    status: 'activo'
  },
  {
    id: 5,
    email: 'supervisor@movex.mx',
    name: 'Jon Doe',
    password: 'password',
    status: 'Inactivo'
  },
  {
    id: 6,
    email: 'supervisor@movex.mx',
    name: 'Jon Doe',
    password: 'password',
    status: 'Inactivo'
  },
  {
    id: 7,
    email: 'supervisor@movex.mx',
    name: 'Jon Doe',
    password: 'password',
    status: 'activo'
  }
]

// const renta_deducible_sin_iva = 6000
// const rate = 0.2/12
//PMT funcion que calcula el pago de un prestamo basado en pagos y tasa de interés constantes.
export function pmt(nper, pv, fv, rate){
  const tasa = rate/12
  let pvif = Math.pow(1 + tasa, nper);
  return (- tasa * (pv * pvif + fv) / (pvif - 1)).toFixed(2)
}

export function comision_apertura( valorFactura ) {
  let comisionCalculada 
  parseFloat(valorFactura) <= 500000 ? comisionCalculada = 5950 :
  parseFloat(valorFactura) <= 700000 ? comisionCalculada = 6960 : comisionCalculada = 8120
  return comisionCalculada 
}

export function ahorro_isr ( desembolso_total, tipo, renta, hibrido ){
  const isr = 0.3
  let renta_deducible_sin_iva
  hibrido && ( hibrido.toUpperCase() === 'SI' ? renta_deducible_sin_iva = 8550 : renta_deducible_sin_iva = 6000 )
  if( (tipo === 'particular' && parseFloat(renta) <= renta_deducible_sin_iva) || tipo === 'trabajo' )
    return (parseFloat(desembolso_total) / 1.16) * isr
  else if( tipo === 'particular' && parseFloat(renta) > renta_deducible_sin_iva ) 
    return ((parseFloat(desembolso_total) / 1.16) * isr) * (renta_deducible_sin_iva / renta)
  else if(tipo === 'gasto')
    return (parseFloat(desembolso_total) / 1.16) * isr
}

export function iva_acreditable ( desembolso_total, tipo, renta, hibrido ) {
  let renta_deducible_sin_iva
  hibrido && ( hibrido.toUpperCase() === 'SI' ? renta_deducible_sin_iva = 8550 : renta_deducible_sin_iva = 6000 )
  if( (tipo === 'particular' && parseFloat(renta) <= renta_deducible_sin_iva) || tipo === 'trabajo' )
    return ( parseFloat(desembolso_total) /  1.16) * 0.16
  else if( tipo === 'particular' && parseFloat(renta) > renta_deducible_sin_iva ) 
    return (( parseFloat(desembolso_total) /  1.16) * 0.16) * (renta_deducible_sin_iva / parseFloat(renta))
  else if(tipo === 'gasto')
    return ( parseFloat(desembolso_total) /  1.16) * 0.16
}

export function costo_neto ( desembolso_total, tipo, renta, hibrido ){
  let ahorroISR = ahorro_isr(parseFloat(desembolso_total), tipo, parseFloat(renta), hibrido)
  let ivaAcreditable = iva_acreditable(parseFloat(desembolso_total), tipo, parseFloat(renta), hibrido)
  return parseFloat(desembolso_total) - parseFloat(ahorroISR) - parseFloat(ivaAcreditable)
}

export function renta_total ( renta_mensual ) {
  return parseFloat(renta_mensual) + parseFloat(renta_mensual) * 0.16
}

export function fit_renta ( monto_financiar, residual, plazo, rate ) {
  const tasa = rate/12
  let calculo_renta = (parseFloat(monto_financiar) - (parseFloat(residual)/((1 + tasa)**plazo))) / ((1-((1 + tasa)**-plazo))/tasa)
  return calculo_renta.toFixed(2)
}

export function fit_enganche ( valorFactura, residual, renta_mensual, plazo, rate ){
  let i = 0
  let res = parseFloat(residual)
  const tasa = rate/12
  while ( i < plazo ){
    // eslint-disable-next-line
    const fn = (x) => x - ( parseFloat(renta_mensual) - ( x * tasa) ) - res 
    res = nr(fn, 0)
    i++
  }
  return ( parseFloat(valorFactura) - res ).toFixed(2)
}

export function fit_residual (valorFactura, renta, aportacion_inicial, plazo, rate) {
  const tasa = rate/12
  let monto_financiar = parseFloat(valorFactura) - parseFloat(aportacion_inicial)
  const fn = (x) => ((monto_financiar - (x/((1 + tasa)**plazo))) / ((1-((1 + tasa)**-plazo))/tasa)) - renta
  let res = nr(fn, 0)
  return res
}