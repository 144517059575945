import { Carousel } from 'primereact/carousel'
import { useState } from 'react'

export default function Carrusel () {
  const [ images ] = useState([ {info: 'CarBrand', image: '0.png'}, {info: 'CarBrand', image: '1.png'}, {info: 'CarBrand', image: '2.png'},
  {info: 'CarBrand', image: '3.png'}, {info: 'CarBrand', image: '4.png'}, {info: 'CarBrand', image: '5.png'}, {info: 'CarBrand', image: '6.png'},
  {info: 'CarBrand', image: '7.png'}, {info: 'CarBrand', image: '8.png'}, {info: 'CarBrand', image: '9.png'} ])
  const responsiveOptions = [
    {
        breakpoint: '1199px',
        numVisible: 4,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 3,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 1
    }
  ]

  const imageTemplate = (element) => {
    return <div className='text-center pb-5'>
      <img src={`assets/${element.image}`} alt={element.info} className='lg:w-[7rem] md:w-[6rem] sm:w-[6rem] w-[6rem] mx-auto' />
    </div>
  }

  return(
    <>
      <Carousel value={images} numVisible={5} numScroll={1} responsiveOptions={responsiveOptions} autoplayInterval={2000} itemTemplate={imageTemplate}
      className='lg:px-10 md:px-5 sm:px-0' circular/>
    </>
  )
}