import { useEffect } from 'react'
import { Redirect } from 'wouter'

export default function Logout ( {setIsLogged} ) {
  useEffect(() => {
    setIsLogged({})
    localStorage.clear()
  }, [setIsLogged])
  
  return (
    <>
      <Redirect to='/login' />
    </>
  )
}