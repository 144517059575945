import { useState } from 'react'
import { useEffect } from 'react'
import { getCotizacionUrl } from '../../helpers/helper'
import Carrusel from '../Carrusel' 
import AutosChart from '../Chart/Autos'
import ListDocuments from '../ListDocuments'

export default function Asesor ( { user } ) {
  const [ dataAutos, setDataAutos ] = useState([])

  useEffect(() => {
    if(user.id !== undefined){
      fetch(`${getCotizacionUrl}/${user.id}`)
        .then(response => response.json())
        .then(res => {
          let autos = []
          let flag = false
          res.map(el => {
            if(autos.length === 0)
              autos.push({...el, cotizaciones: 1})
            else{
              autos.map(auto => {
                if(el.nombre_auto === auto.nombre_auto && el.marca_auto && auto.nombre_auto){
                  auto.cotizaciones = auto.cotizaciones + 1
                  flag = true
                }
                return null
              })
              flag === false ? autos.push({...el, cotizaciones: 1}) : flag = false
            }
            return null
          })
  
          autos.sort((x,y) => x.cotizaciones - y.cotizaciones)
          autos.reverse()
         if(autos.length > 10) autos.length = 10
          setDataAutos(autos)
        })
    }
  }, [user.id])

  return(
    <>
      <div className='w-5/6 border mx-auto mt-10 shadow-md rounded-md border-2 mb-10'>
        <h1 className='font-semibold text-center text-4xl text-gray-900 my-10 py-5'>{`¡Bienvenido ${user.nombres} ${user.apellido_paterno}!`}</h1>
        <section className='my-10 py-5'>
          <Carrusel />
          { dataAutos.length > 0 && <div className='w-1/2 mx-auto py-5 px-5 border my-10 rounded-md shadow-md bg-slate-50'>
            <h3 className='text-center font-semibold my-5 text-xl'>Tus cotizaciones del mes</h3>
            <AutosChart data={dataAutos} />
          </div> }
        </section>
        <div className='mx-10'><ListDocuments /></div>
      </div>
    </>
  )
}