import { Badge } from 'primereact/badge'
import { Tooltip } from 'primereact/tooltip'

export default function BadgeInfo ( {message} ) {

  return(
    <>
      <Tooltip target='.badge-info' content={message} position='right' style={{fontSize: '0.8rem'}} />
      <Badge className='badge-info p-badge-dot ml-1' value='?' severity='help'></Badge>
    </>
  )
}