import { useState, useEffect, useRef } from 'react'
import { getCarUrl, putPromoUrl } from '../../helpers/helper'
import { InputNumber } from 'primereact/inputnumber'
import { Toast } from 'primereact/toast'
import { Calendar } from 'primereact/calendar'
import BadgeInfo from '../BadgeInfo'
import { addLocale } from 'primereact/api'

//Selector de marca
function BrandSelector ( {brand, autos, setModel, setModelState, setPrecio, setVigencia, setPorcentajePromo, setMontoDescuento, setMonto} ) {
  return (
    <>
      <select id="brandSelector" className="relative block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 
      placeholder-gray-500 focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm" 
      onClick={(e) => {
        let result = []
        if(e.target.value !== 'none'){
          autos.map(el => {
            el.marca === e.target.value && result.push({modelo: el.modelo, anio: el.anio, id: el.id, precio: el.precio})
            return null
          })
          setModel(result)
          setModelState(true)
        }else{
          setModel([])
          setModelState(false)
          setPrecio({real: 0, descuento: 0})
          setVigencia({inicio: '', fin: ''})
          setPorcentajePromo(0)
          setMontoDescuento(0)
          document.getElementById('monto').value = ''
          document.getElementById('percent').value = ''
          setMonto(false)
        }
      }}>
        <option value='none'>Ninguna Marca Seleccionada</option>
        { brand.map(el => (<option key={el} value={el}>{el}</option>)  ) }
      </select>
    </>
  )
}

//Selector de Agencia
function ModelSelector ( {model, modelState, setPrecio} ){
  return (
    <select id="modelSelector" placeholder='Modelo' disabled={!modelState} className="my-4 relative block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 
    placeholder-gray-500 focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm" 
    onClick={(e) => {
      if( e.target.value !== 'all'){
        let valor = e.target.value.split(' ')
        setPrecio({real: parseFloat(valor[1]), descuento: parseFloat(valor[1])})
      }else{
        setPrecio({real: 0, descuento: 0})
      }
    }}>
      <option value='all'>Todos</option>
      { model.map(el => ( <option key={el.id} value={`${el.id} ${el.precio}`}>{`${el.modelo} ${el.anio}`}</option>)) }
    </select>
  )
}

export default function Promociones () {
  const toast = useRef(null)
  const [ brand, setBrand ] = useState([])
  const [ model, setModel ] = useState([])
  const [ modelState, setModelState ] = useState(false)
  const [ autos, setAutos ] = useState([])
  const [ precio, setPrecio ] = useState({real: 0, descuento: 0})
  const [ montoDescuento, setMontoDescuento ] = useState(0)
  const [ porcentajePromo, setPorcentajePromo ] = useState(0)
  const [ vigencia, setVigencia ] = useState({inicio: '', fin: ''})
  const [ monto, setMonto ] = useState(false)

  useEffect(() => {
    fetch(getCarUrl).then(res => res.json())
      .then(data => {
        let marca = []
        let modelo = []
        setAutos(data)
        data.map(el => {
          marca.push(el.marca)
          modelo.push({modelo: el.modelo, anio: el.anio, id: el.id, precio: el.precio})
          return null
        })
        setBrand([...new Set(marca)])
        setModel([...new Set(modelo)])
      })
  }, [])

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar'
  })

  const showInfo = (severidad, resumen, message) => {
    toast.current.show({severity: severidad, summary: resumen, detail: message, life: 3000})
  }

  const handlerSubmit = (e) => {
    e.preventDefault()
    if(document.getElementById('brandSelector').value !== 'none'){
      if(document.getElementById('modelSelector').value !== 'all'){
        let valor = document.getElementById('modelSelector').value.split(' ')
        let discount = document.getElementById('percent').value === '' ? 0 : parseFloat(document.getElementById('percent').value) / 100
        const data = {
          promocion_inicio: vigencia.inicio.toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' }),
          promocion_fin: vigencia.fin.toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' }) ,
          monto_promocion: montoDescuento,
          porcentaje_promocion: discount
        }
        fetch(`${putPromoUrl}/${valor[0]}`, {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }).then((res) => res.json())
          .then((result) => {
            result.message === 'Success' && showInfo('success', 'Success','La promoción fue aplicada de forma exitosa.')
            setMontoDescuento(0)
            setPrecio({real: 0, descuento: 0})
            setVigencia({inicio: '', fin: ''})
            setModelState(false)
            document.getElementById('modelSelector').value = 'all'
            document.getElementById('brandSelector').value = 'none'
            document.getElementById('percent').value = ''
            document.getElementById('monto').value = ''
          })
      }
      else{
        console.log(montoDescuento)
        let brand = document.getElementById('brandSelector').value
        let discount = document.getElementById('percent').value === '' ? 0 : parseFloat(document.getElementById('percent').value) / 100
        const data = {
          promocion_inicio: vigencia.inicio.toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' }),
          promocion_fin: vigencia.fin.toLocaleDateString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' }) ,
          monto_promocion: montoDescuento,
          porcentaje_promocion: discount
        }
        fetch(`${putPromoUrl}/brand/${brand}`, {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }).then((res) => res.json())
          .then((result) => {
            result.message === 'Success' && showInfo('success', 'Success','La promoción fue aplicada de forma exitosa.')
            setMontoDescuento(0)
            setPrecio({real: 0, descuento: 0})
            setVigencia({inicio: '', fin: ''})
            setModelState(false)
            document.getElementById('modelSelector').value = 'all'
            document.getElementById('brandSelector').value = 'none'
            document.getElementById('percent').value = ''
            document.getElementById('monto').value = ''
          })
      }
    }
    else showInfo('info', 'Informacion:','Asegurese de llenar los datos necesarios para aplicar la promoción.')
  }

  const handlerClean = (e) => {
    e.preventDefault()
    document.getElementById('brandSelector').value = 'none'
    setModel([])
    setModelState(false)
    setPrecio({real: 0, descuento: 0})
    setVigencia({inicio: '', fin: ''})
    setPorcentajePromo(0)
    setMontoDescuento(0)
    document.getElementById('monto').value = ''
    document.getElementById('percent').value = ''
    setMonto(false)
  }

  return (
    <>
      <Toast ref={toast} />
      <div className='w-full max-w-md space-y-8 py-10 px-5 border  border-gray-200 rounded-md shadow-md'>
        <form className='py-4 px-5 text-center' onSubmit={handlerSubmit}>
          <div className='grid grid-cols-12 mt-6 mb-10'>
            <div></div>
            <h2 className='col-span-10 text-center text-3xl font-bold tracking-tight text-gray-900'>Aplicar Promoción</h2>
            <div className='pt-2'><BadgeInfo message='En las promociones se aplica primero el porcentaje de descuento al valor del vehículo 
            y después se resta el monto de descuento.' /></div>
          </div>
          <BrandSelector brand={brand} autos={autos} setModel={setModel} setModelState={setModelState} setPrecio={setPrecio} setVigencia={setVigencia} 
          setPorcentajePromo={setPorcentajePromo} setMontoDescuento={setMontoDescuento} setMonto={setMonto}/>
          <ModelSelector model={model} modelState={modelState} setPrecio={setPrecio} />
          { precio.real > 0 && <div className='grid grid-cols-2 mb-4 p-fluid'>
            <p className='py-2 text-left px-4 border rounded-l-md text-sm'>Precio Vigente</p>
            <InputNumber placeholder='Precio' value={precio.real} mode="currency" currency="USD" locale="en-US" disabled
              className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 sm:text-sm" 
              inputStyle={{fontSize: '0.85rem', padding: '.5rem 1.5rem', textAlign: 'right'}} />
          </div> 
          }
          <div className='grid grid-cols-12 mb-4'>
            <InputNumber inputId="percent" placeholder='Porcentaje de descuento %' suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 col-span-9" 
            inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', textAlign: 'right'}} onValueChange={(e) => setPorcentajePromo(e.value)} 
            disabled={monto}/>
            <button className='col-span-3 truncate border-2 border-indigo-300 rounded-r-lg hover:bg-indigo-50 text-indigo-500' disabled={precio.real === 0}
            onClick={(e) => {
              e.preventDefault()
              let promo = parseFloat(precio.real) - precio.real*(parseFloat(porcentajePromo/100)) - parseFloat(montoDescuento)
              precio.real > 0 && setPrecio( {...precio, descuento: promo } )
            }}>Aplicar</button>
          </div>
          <div className='grid grid-cols-12 mb-4'>
            <InputNumber inputId="monto" placeholder='Monto de Descuento -$' className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 col-span-9" 
            mode="currency" currency="USD" locale="en-US" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem', textAlign: 'right'}} onValueChange={(e) => setMontoDescuento(e.value)}/>
            <button className='col-span-3 truncate border-2 border-indigo-300 rounded-r-lg hover:bg-indigo-50 text-indigo-500' disabled={precio.real === 0}
            onClick={(e) => {
              e.preventDefault()
              let promo = parseFloat(precio.real) - precio.real*(parseFloat(porcentajePromo/100)) - parseFloat(montoDescuento)
              precio.real > 0 && setPrecio( {...precio, descuento: promo } )
            }}>Aplicar</button>
          </div>
          { precio.real > 0 && <div className='grid grid-cols-2 mb-4 p-fluid'>
            <p className='py-2 text-left px-4 border rounded-l-md text-sm'>Precio con Descuento</p>
            <InputNumber placeholder='Precio' value={precio.descuento} mode="currency" currency="USD" locale="en-US" 
            disabled className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 sm:text-sm" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1.5rem', textAlign: 'right'}} />
          </div> 
          }
          <Calendar placeholder='Inicio de la vigencia' value={vigencia.inicio} inputStyle={{fontSize: '0.85rem', padding: '.5rem 1.5rem'}}
          onChange={(e) => setVigencia({...vigencia, inicio:  e.value })} required
          className="mb-4 w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 sm:text-sm" dateFormat="dd/mm/yy" locale='es'></Calendar>
          <Calendar placeholder='Fin de la vigencia' value={vigencia.fin} inputStyle={{fontSize: '0.85rem', padding: '.5rem 1.5rem'}}
          onChange={(e) => setVigencia({...vigencia, fin: e.value })} required
          className="mb-4 w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 sm:text-sm" dateFormat="dd/mm/yy" locale='es'></Calendar>
          <button className='mt-5 mb-4 bg-blue-600 px-2 py-1 text-white w-full border rounded-md hover:bg-blue-700 hover:ring-blue-200 hover:ring-2 hover:border-indigo-200'>Enviar</button>
          <button className='mb-5 px-2 py-1 w-full border-2 border-indigo-400 text-indigo-700 rounded-md hover:bg-indigo-50 hover:ring-blue-200 hover:ring-2' onClick={handlerClean}>Limpiar</button>
        </form>
      </div>
    </>
  )
}