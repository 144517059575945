import { useEffect, useState } from 'react'
import { getCotizacionUrl } from '../../helpers/helper'
import { useLogin } from '../../hooks/useLogin'
import AutosChart from '../Chart/Autos'
import AsesoresChart from '../Chart/Asesores'
import Carrusel from '../Carrusel'
import ListDocuments from '../ListDocuments'

export default function Dashboard () {
  const [ dataAutos, setDataAutos ] = useState({})
  const [ dataAsesores, setDataAsesores ] = useState({})
  const { user } = useLogin()

  useEffect(() => {
    fetch(getCotizacionUrl)
      .then(response => response.json())
      .then(res => {
        let autos = []
        let asesores = []
        let flag = false
        let bandera = false
        res.map(el => {
          if(autos.length === 0)
            autos.push({...el, cotizaciones: 1})
          else{
            autos.map(auto => {
              if(el.nombre_auto === auto.nombre_auto && el.marca_auto && auto.nombre_auto){
                auto.cotizaciones = auto.cotizaciones + 1
                flag = true
              }
              return null
            })
            flag === false ? autos.push({...el, cotizaciones: 1}) : flag = false
          }
          if(asesores.length === 0)
            asesores.push({...el, cotizaciones: 1})
          else{
            asesores.map(asesor => {
              if(el.nombre_agente === asesor.nombre_agente && el.apellido_paterno_agente && asesor.apellido_paterno_agente && el.apellido_materno_agente === asesor.apellido_materno_agente ){
                asesor.cotizaciones = asesor.cotizaciones + 1
                bandera = true
              }
              return null
            })
            bandera === false ? asesores.push({...el, cotizaciones: 1}) : bandera = false
          }
          return null
        })

        autos.sort((x,y) => x.cotizaciones - y.cotizaciones)
        autos.reverse()
       if(autos.length > 10) autos.length = 10
        setDataAutos(autos)

        asesores.sort((x,y) => x.cotizaciones - y.cotizaciones)
        asesores.reverse()
       if(asesores.length > 10) asesores.length = 10
        setDataAsesores(asesores)
      })
  }, [])

  return(
    <>
      <div className='mx-10 mb-10 p-5 border rounded-md border-2 shadow-md'>
        <h2 className='text-center my-10 font-bold text-3xl lg:pb-10 md:pb-10 pb-0 text-gray-900'>{`¡Bienvenido ${user.nombres} ${user.apellido_paterno}!`}</h2>
        <Carrusel />
        <section className='grid grid-cols-12 gap-10 mt-10 mb-20 px-10 pt-5'>
        { Object.keys(dataAutos).length > 0 &&
          <div className='lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12 bg-slate-50 border rounded-lg shadow-md shadow-indigo-200'>
            <h3 className='text-center text-xl font-semibold text-gray-900 my-5'>Vehículos más cotizados en el mes</h3>
            <AutosChart data={dataAutos} /> 
          </div>
        }
        { Object.keys(dataAsesores).length > 0 &&
          <div className='lg:col-span-6 md:col-span-6 sm:col-span-12 col-span-12 bg-slate-50 border rounded-lg shadow-md shadow-indigo-200'>
            <h3 className='text-center text-xl font-semibold text-gray-900 my-5'>Asesores con más cotizaciones en el mes</h3>
            <AsesoresChart data={dataAsesores} /> 
          </div>
        }
        </section>
        <div className='mx-10'><ListDocuments /></div>
      </div>
    </>
  )
}