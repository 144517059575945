import { useState } from 'react'
import { Chart } from 'primereact/chart'

export default function ChartComponent () {
    const [chartData] = useState({
        labels: ['Juan Perez', 'Alejandro Martinez', 'Jan Salazar', 'Pedro Lopez'],
        datasets: [
            {
                data: [300, 50, 100, 200],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                    "#FFA726",
                    '#9851F3'
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                    "#FFB74D",
                    '#A568F3'
                ]
            }
        ]
    })

    const [lightOptions] = useState({
        plugins: {
            title: {
                display: true,
                text: 'Ventas del Mes',
                font: { size: 40, weight: 500 }
            },
            legend: {
                position: 'left',
                marginRight: '5rem',
                labels: {
                    color: '#495057',
                    font: { size: 20, weight: 200 },
                    padding: 18,
                    boxWidth: 20,
                }
            }
        }
    })

  return(
    <>
        <div className="grid justify-items-center mt-20 mx-20 py-10 border border-2 rounded-lg shadow-md shadow-indigo-200">
            {/* <h1 className='mt-5 mb-10 text-4xl font-semibold text-gray-900 text-center'>Ventas del Mes</h1> */}
            <Chart type="pie" data={chartData} options={lightOptions} className='w-3/6 self-center' />
        </div>
    </>
  )
}