import { Link } from 'wouter'
import { useState, useEffect, useRef } from 'react'
import { useLogin } from '../../hooks/useLogin'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'primereact/tooltip'
import { getCarUrl, postCotizacionUrl, getInsuranceIdUrl, getPromoUrl, pmt } from '../../helpers/helper'
import ArrendamientoSA from '../Arrendamiento/ArrendamientoSA'
import Arrendamiento from '../Arrendamiento/Arrendamiento'
import Consultants from '../Consultants'

import BadgeInfo from '../BadgeInfo'
import { useCotizacion } from '../../hooks/useCotizacion'
import { useLocalStorage } from '../../hooks/useLocalStorage'

function Selector ( {setTipo} ) {
  return (
    <>
      <select id="field" className="relative block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 
      placeholder-gray-500 focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm"
      onClick={(e) => setTipo(e.target.value)}>
        <option value="particular">Particular</option>
        <option value="trabajo">Trabajo</option>
      </select>
    </>
  )
}

function InsuranceSelector ( {insurance, setSeguro} ) {
  return (
    <>
      <select className="relative block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm"
      onClick={(e) => setSeguro(parseFloat(e.target.value))}>
        {insurance.map(el => (
          <option key={el.id} value={el.precio}>{el.aseguradora}</option>
        ))}
      </select>
    </>
  )
}

//Selector de marca
function BrandSelector ( {brand, autosData, setModelo, setAutoState, setVehiculo, selectedBrand,setSelectedBrand} ) {
  return (
    <>
      {/* <select id='brand' className="relative block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 
      placeholder-gray-500 focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm" 
      onClick={(e) => {
        let result = []
        autosData.map(el => {
          el.marca === e.target.value && result.push({modelo: el.modelo, anio: el.anio, id: el.id, precio: el.precio, hibrido: el.hibrido})
          return null
        })
        setModelo(result.sort((x, y) => x.modelo.localeCompare(y.modelo)))
        setAutoState(true)
      }}>
        <option value='none'>Ninguna Marca Seleccionado</option>
        { brand.map(el => (<option key={el} value={el}>{el}</option>)  ) }
      </select> */}
       <select id='brand' required 
        className="relative block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 
        placeholder-gray-500 focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm" 
        value={selectedBrand}
        onChange={(e) => {
          const selectedValue = e.target.value;
          const result = autosData.filter(el => el.marca === selectedValue);
          setModelo(result.sort((x, y) => x.modelo.localeCompare(y.modelo)));
          setAutoState(true);
          setSelectedBrand(selectedValue);
        }}>
        <option value='none'>Ninguna Marca Seleccionado</option>
        {brand.map(el => (<option key={el} value={el}>{el}</option>))}
      </select>
    </>
  )
}

//Selector de Agencia
function ModelSelector ( {modelo, autoState, setFactura, setValorFactura, setInsurance, setSeguro, setVehiculo, vehiculo, setHibrido, promocion, setPromocion, parametros, importes, setImportes} ){
  return (
    
      <select id="modelo" placeholder='Agencia' disabled={!autoState} className="relative block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 
      placeholder-gray-500 focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm"
      onClick={(e) => {
        // console.log(e)
        let valor = e.target.value.split(' ')
        setFactura(parseFloat(valor[0]))
        setValorFactura(0)
        setPromocion({...promocion, id: valor[1]})
        // console.log(parametros)
        let comision_apertura
        //v1
          // if (parseFloat(valor[0]) <= parametros.limite[0]) {
          //     comision_apertura = parametros.comision[0];
          // } else if (parseFloat(valor[0]) <= parametros.limite[1]) {
          //     comision_apertura = parametros.comision[1];
          // } else if (parseFloat(valor[0]) <= parametros.limite[2]) {
          //     comision_apertura = parametros.comision[2];
          // } else if (parseFloat(valor[0]) <= parametros.limite[2]) {
          //     comision_apertura = parametros.comision[3];
          // } else {
          //     comision_apertura = parametros.comision[4];
          // }

        //v2
        if(parseFloat(valor[0]) <= parametros.limite[0]){// <=300,000
          comision_apertura = parametros.comision[0]
        }else if(parseFloat(valor[0]) <= parametros.limite[1]){// <=500,000
          comision_apertura =parametros.comision[1]
        }else if(parseFloat(valor[0]) <= parametros.limite[2]){// <=700,000          
          comision_apertura = parametros.comision[2]
        }else if(parseFloat(valor[0]) <= parametros.limite[3]){// <=900,000        
          comision_apertura = parametros.comision[3]
        }else if(parseFloat(valor[0]) > parametros.limite[3]){// > 900,000
          comision_apertura = parametros.comision[4]
        }
  
        // console.log(comision_apertura)
        // return
        setImportes({...importes, comision_apertura: comision_apertura})
        if(valor.length > 1 && valor[1] !== '-1'){
          setVehiculo(`${document.getElementById(valor[1]).innerHTML}`)
          setHibrido(valor[2].toLowerCase())
          fetch(`${getInsuranceIdUrl}/${valor[1]}`)
            .then(res => res.json())
            .then( result => {
              let insuranceTemp = result.sort((x, y) => x.precio - y.precio)
              setInsurance(insuranceTemp)
              setSeguro(parseFloat(insuranceTemp[0].precio))
            })
        }else{
          setVehiculo(``)
          setHibrido('')
          setInsurance([])
          setSeguro(0)
          setPromocion({id:-1, porcentaje_promocion: 0, monto_promocion: 0})
        }
      }}>
        <option value='0 -1'>Ningun Modelo Seleccionado</option>
        { Object.keys(modelo).length > 0 && modelo.map(el => ( <option key={`${el.id}`} id={el.id} value={`${el.precio} ${el.id} ${el.hibrido}`}>{`${el.modelo} ${el.anio}`}</option>)) }
      </select>

  )
}

/*Vendedor Page*/
export default function Cotizador ( ) {
  
  const toast = useRef(null)
  const { user, setUser } = useLogin()
  // const { setValorFactura, setRentaSA, setResidual, setTipo, vehiculo, setVehiculo, seguro, setSeguro, setCotizacion, hibrido, setHibrido, 
  //   tipo, parametros, importes, setImportes } = useCotizacion()
  const { valorFactura, setValorFactura,setRenta, rentaSA,setRentaSA,residual, setResidual, residualf5710, setResidualf5710, setTipo, vehiculo, setVehiculo, seguro, setSeguro,cotizacion, setCotizacion, hibrido, setHibrido, 
    tipo, parametros, importes, setImportes } = useCotizacion()

  const [ factura, setFactura ] = useState(0)
  const [ insurance, setInsurance ] = useState([])
  
  
  const [brand, setBrand] = useState([])
  const [modelo, setModelo] = useState([])
  const [autosData, setAutosData] = useState([])
  const [autoState, setAutoState] = useState(false)
  const [ consultant, setConsultant ] = useState({id: user.id, marca: user.marca, agencia: user.agencia, nombres: user.nombres, apellido_paterno: user.apellido_paterno, apellido_materno: user.apellido_materno})

  const [promocion, setPromocion] = useState({id:-1, porcentaje_promocion: 0, monto_promocion: 0})

  const [displayResponsive, setDisplayResponsive] = useState(false)

  const showToast = (severidad, resumen, message) => {
    toast.current.show({severity: severidad, summary: resumen, detail: message, life: 3000})
  }


  /**Codigo agregado */

   /**Funcion que ayudara a guardar los datos del cliente */
   const [ client_name, setClient_name ] = useLocalStorage('client_name','')
    
   const [ client_email, setClient_email ] = useLocalStorage('client_email','')
   
   const [ client_phone, setClient_phone ] = useLocalStorage('client_phone','')
   
   const  limpiarStorage =()=>{
     setClient_name('')
     setClient_email('')
     setClient_phone('')
     setSelectedBrand('none')
     setValorFactura('')
     setRentaSA('')
     setRenta([])
     const selectElement = document.getElementById("modelo");

     // Establece la opción por defecto
     selectElement.selectedIndex = 0; 
     setVehiculo(``)
     setHibrido('')
     setFactura(0);
     setInsurance([])
     setSeguro(0)
     setPromocion({id:-1, porcentaje_promocion: 0, monto_promocion: 0})
     setImportes({comision_apertura: 0, localizador: [parametros.localizador[0], parametros.localizador[1], parametros.localizador[2]]}) 
     
   }
   const [ selectedBrand, setSelectedBrand ] = useLocalStorage('selectedBrand','none')

  //Efecto que se ejecuta cuando cambia el estado de importes
  useEffect(() => {
        
    // Guardar los datos en el almacenamiento local
    localStorage.setItem('valorFactura_data', JSON.stringify(valorFactura));
    localStorage.setItem('rentaSA_data', JSON.stringify(rentaSA));
    localStorage.setItem('residual_data', JSON.stringify(residual));
    localStorage.setItem('residualf5710_data', JSON.stringify(residualf5710));
    localStorage.setItem('tipo_data', JSON.stringify(tipo));
    localStorage.setItem('vehiculo_data', JSON.stringify(vehiculo));
    localStorage.setItem('seguro_data', JSON.stringify(seguro));
    localStorage.setItem('cotizacion_data', JSON.stringify(cotizacion));
    localStorage.setItem('hibrido_data', JSON.stringify(hibrido));
    localStorage.setItem('importes_data', JSON.stringify(importes));
    
    // hibrido, setHibrido

  }, [ valorFactura, rentaSA, residual,residualf5710, tipo,vehiculo, seguro, cotizacion, hibrido, importes])


  //Efecto que se ejecuta cuando el componente se monta para cargar los datos del almacenamiento local
  useEffect(() => {
    // Obtener los datos guardados del almacenamiento local
    const storedDatavalor_valorFactura_data = localStorage.getItem('valorFactura_data');
    const storedDatavalor_rentaSA_data = localStorage.getItem('rentaSA_data');
    const storedDatavalor_residual_data = localStorage.getItem('residual_data');
    const storedDatavalor_residualf5710_data = localStorage.getItem('residualf5710_data');
    const storedDatavalor_tipo_data = localStorage.getItem('tipo_data');
    const storedDatavalor_vehiculo_data = localStorage.getItem('vehiculo_data');
    const storedDatavalor_seguro_data = localStorage.getItem('seguro_data');
    const storedDatavalor_cotizacion_data = localStorage.getItem('cotizacion_data');
    const storedDatavalor_hibrido_data = localStorage.getItem('hibrido_data');
    const storedDatavalor_importes_data = localStorage.getItem('importes_data');
 
    // Verificar si hay datos guardados y actualizar el estado si existen
    if (storedDatavalor_valorFactura_data) {
      setValorFactura(JSON.parse(storedDatavalor_valorFactura_data));
      setRentaSA(JSON.parse(storedDatavalor_rentaSA_data));
      setResidual(JSON.parse(storedDatavalor_residual_data));
      setResidualf5710(JSON.parse(storedDatavalor_residualf5710_data));
      setTipo(JSON.parse(storedDatavalor_tipo_data));
      setVehiculo(JSON.parse(storedDatavalor_vehiculo_data));
      setSeguro(JSON.parse(storedDatavalor_seguro_data));
      setCotizacion(JSON.parse(storedDatavalor_cotizacion_data));
      setHibrido(JSON.parse(storedDatavalor_hibrido_data));
      setImportes(JSON.parse(storedDatavalor_importes_data));
      
    }
  }, []);


  // const openInNewTab = (url) => {
  
  //   const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  //   if (newWindow) newWindow.opener = null;
  // };

  // function openInNewTab(path) {
  //   const url = `${window.location.origin}${path}`;
  //   console.log(`URL generada: ${url}`);
  //   const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  //   if (newWindow) {
  //     try {
  //       newWindow.opener = null;
  //     } catch (error) {
  //       console.error('Error al asignar opener:', error);
  //     }
  //   } else {
  //     console.error('No se pudo abrir la ventana nueva.');
  //   }
  // }
  //funcional
  function openInNewTab(path) {
    const url = `${window.location.origin}${path}`;
    console.log(`URL generada: ${url}`);

    // Crear un elemento <a> y simular un clic en él
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  /**Termina codigo agregado */


















  

  useEffect( () => {
    fetch(getCarUrl).then(res => res.json())
    .then(data => {
      let marca = []
      let modelo = []
      setAutosData(data)
      data.map(el => {
        marca.push(el.marca)
        modelo.push({modelo: el.modelo, anio: el.anio, id: el.id, precio: el.precio})
        return null
      })
      setBrand([...new Set(marca.sort())])
      setModelo([...new Set(modelo.sort((x, y) => x.modelo.localeCompare(y.modelo)))])
    })
  }, [])
    
  const handleCostos = (e) => {
    e.preventDefault()
    let idAuto = document.getElementById('modelo').value
    idAuto = idAuto.split(" ")
    setValorFactura(factura)
    setUser({...user, cotizaciones: user.cotizaciones + 1})
    // Cotizacion sin aportacion inicial
    setResidual([factura * 0.5, factura * 0.4, factura * 0.3])
    
    // console.log([ pmt(24, -factura, factura*0.5, parametros.rate),  pmt(36, -factura, factura*0.4, parametros.rate),  pmt(48, -factura, factura*0.3, parametros.rate)])
    // console.log(`valor factura= ${factura}`)
    // console.log(parametros)
    setResidualf5710([factura * 0.05, factura * 0.07, factura * 0.10])


    setRentaSA([ pmt(24, -factura, factura*0.5, parametros.rate),  pmt(36, -factura, factura*0.4, parametros.rate),  pmt(48, -factura, factura*0.3, parametros.rate)])

    const dataSend = {   
        id_agente: user.id,
        nombre_agente: user.nombres,
        apellido_paterno_agente: user.apellido_paterno,
        apellido_materno_agente: user.apellido_materno,
        email_agente: user.email,
        marca_agente: user.marca,
        agencia_agente: user.agencia,
        cotizaciones_agente: user.cotizaciones + 1,
        nombre_cliente: document.getElementById('client_name').value,
        email_cliente: document.getElementById('client_email').value,
        telefono_cliente: document.getElementById('client_phone').value,
        nombre_auto: vehiculo,
        marca_auto: document.getElementById('brand').value,
        id_auto: parseInt(idAuto[1]),
        precio_auto: factura,
        cotizar_tercero: consultant.id !== user.id ? `${consultant.nombres} ${consultant.apellido_paterno} ${consultant.apellido_materno}` : null
    }
    const cotizacionSend = {
      id_agente: consultant.id,
      nombre_agente: consultant.nombres,
      apellido_paterno_agente: consultant.apellido_paterno,
      apellido_materno_agente: consultant.apellido_materno,
      email_agente: user.email,
      agencia_agente: consultant.agencia,
      marca_agente: consultant.marca,
      cotizaciones_agente: user.cotizaciones + 1,
      nombre_cliente: document.getElementById('client_name').value,
      email_cliente: document.getElementById('client_email').value,
      telefono_cliente: document.getElementById('client_phone').value,
      nombre_auto: vehiculo,
      marca_auto: document.getElementById('brand').value,
      id_auto: parseInt(idAuto[1]),
      precio_auto: factura,
    }
    setCotizacion(cotizacionSend)

    if(tipo !== 'none' && vehiculo !== ''){
      // fetch(postCotizacionUrl, {
      //   method: 'POST', 
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(dataSend),
      // }).then((res) => res.json())
      //   .then((result) => console.log('Server Message: ' + result.message))
      
      fetch(postCotizacionUrl, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataSend),
      }).then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.text(); // Lee la respuesta como texto
      }).then((text) => {
        console.log('Response Text:', text);
        try {
          const result = JSON.parse(text); // Intenta analizar la respuesta como JSON
          console.log('Server Message:', result.message);
        } catch (e) {
          console.error('Error parsing JSON:', e);
        }
      }).catch((error) => {
        console.error('Fetch Error:', error);
      });
      
    }else {
      showToast('warn', 'Cuidado', 'Asegurese de completar el formulario antes de generar una cotización.')
    }
  }



  const handlerPromo = (e) => {
    e.preventDefault()
    if(promocion.id !== -1 && promocion.id !== -2){
      fetch(`${getPromoUrl}/${promocion.id}`).then(res => res.json())
        .then(data => {
          //console.log(data.length)
          if(data.length === 0)
            showToast('info', 'Ooops', 'No hay promociones disponibles para este vehículo')
          else {
            setFactura(factura - data[0].monto_promocion - (factura*data[0].porcentaje_promocion));
            showToast('success', 'Buena Suerte', 
            `Se aplico una promoción disponible al vehículo: Porcentaje de descuento: ${data[0].porcentaje_promocion*100}%, 
            Monto: ${data[0].monto_promocion}`);
          } 
          setPromocion({id:-2, porcentaje_promocion: 0, monto_promocion: 0})
        })
    }else{
      promocion.id === -1 ? showToast('warn', 'Cuidado', 'Selecciona un vehículo antes de aplicar una promoción.') 
      : showToast('warn', 'Cuidado', 'Ya se ha aplicado un promoción al vehículo seleccionado.')
    }
  }
    
  return(
    <>
      <section>
      <Toast ref={toast}></Toast>
      <Tooltip target='.btn-promo' content='Aplicar Promoción' position='right' style={{fontSize: '0.8rem'}} />
        <div className="lg:mx-auto md:mx-auto sm:mx-5 text-center my-5 sm:w-full border border-gray-300 rounded-lg py-5 lg:w-11/12">
          <aside className='grid grid-cols-12 my-6 mx-1'>
            <div className='lg:col-span-1 md:col-span-2 sm:col-span-0 col-span-0'></div>
            <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 lg:col-span-10 md:col-span-8 sm:col-span-9 col-span-9">Cotizador</h2>
            <div className='mr-2 lg:col-span-1 md:col-span-2 sm:col-span-3 col-span-3 w-fit'>
              <Button label='Ayuda' onClick={() => setDisplayResponsive(true)} className='p-button-warning p-button-rounded p-button-sm'/>
            </div>
          </aside>
          <Dialog header="Instrucciones Cotizador" visible={displayResponsive} onHide={() => setDisplayResponsive(false)} breakpoints={{'960px': '75vw'}} style={{width: '50vw'}}>
            <ol className='ml-4'>
              <li><span className='font-semibold mr-2'>1.</span>Llene los campos solicitados de Datos del Cliente;  {`(Email) y (Teléfono)`} del cliente son campos opcionales.</li>
              <li><span className='font-semibold mr-2'>2.</span>Seleccione el uso que se le dará al vehículo a cotizar en el desplegable de “Tipo de Auto”, 
              después seleccione marca y modelo, y por último escoja la compañía de seguros en el desplegable que sea de mayor agrado del cliente. </li>
              <span className='font-bold text-sm'>Nota: </span><span>la compañía de seguro predeterminada es la de menor costo en el vehículo.</span>
              <li><span className='font-semibold mr-2'>3.</span>Dar click en el botón de <span className='text-blue-700 font-semibold'>“Calcular”</span> para generar los cálculos 
              con los datos predeterminados de la cotización.</li>
              <li><span className='font-semibold mr-2'>4.</span>Si requiere ajustar los valores de <span className='underline'>Renta</span>, <span className='underline'>Aportación inicial</span> y/o <span className='underline'>Residual</span>, 
              sustituya los valores por los deseados uno a uno dando click en el botón de <span className='text-blue-700 font-semibold'>“Ajustar”</span> que aparecerá una vez que cambie el valor deseado.</li>
              <li><span className='font-semibold mr-2'>5.</span>Si los ajustes ya son los esperados, vaya a la sección de <span className='text-blue-700 font-semibold'>“Imprimir Archivo”</span> 
              y seleccione la opción que desea.</li>
              <h3 className='mt-2 font-semibold underline text-gray-500'>Nota</h3>
              <li><span className='font-semibold mr-2'>1.</span>Recuerda que al regresar al cotizador los datos ajustados desaparecerán y se mostrarán los valores predeterminados.</li>
            </ol>
          </Dialog>

          <section className="mx-auto px-5 pb-5 lg:grid lg:grid-cols-2">
            <form className='px-5 w-5/6 mx-auto' onSubmit={handleCostos}>
              {/* Datos de la cotizacion */}
              <h4 className='mt-5 mb-4 font-semibold text-lg'>Datos del Asesor</h4>
              { user.tipo === 'supervisor' ? <Consultants setConsultant={setConsultant} consultant={consultant} /> :
                <aside className='grid grid-cols-2 gap-4 p-fluid'>
                  <InputText placeholder="Distribuidor" value={`${user.marca} ${user.agencia}`} disabled style={{margin: '1rem 0', fontSize: '0.85rem', padding: '.5rem 1rem'}} className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm"/>
                  <InputText placeholder="Asesor" value={ `${user.nombres} ${user.apellido_paterno} ${user.apellido_materno}` } disabled style={{margin: '1rem 0', fontSize: '0.85rem', padding: '.5rem 1rem'}} className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm"/>
                </aside>
              }
              
              <h4 className='my-4 font-semibold text-lg'>Datos del Cliente</h4>
              <InputText id='client_name' value={client_name} onChange={e => setClient_name(e.target.value)} placeholder="Nombre" style={{margin: '1rem 0', fontSize: '0.85rem', padding: '.5rem 1rem'}} className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" autoFocus required/>
              <InputText id='client_email' value={client_email} placeholder="Email (Opcional)"    onChange={e => setClient_email(e.target.value)}  style={{margin: '1rem 0', fontSize: '0.85rem', padding: '.5rem 1rem'}} className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm"/>
              <InputText id='client_phone' value={client_phone} placeholder="Teléfono (Opcional)" onChange={e => setClient_phone(e.target.value)}  style={{margin: '1rem 0', fontSize: '0.85rem', padding: '.5rem 1rem'}} className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm"/>
              
              {/* Datos del Vehiculo */}
              <h4 className='my-4 font-semibold text-lg'>Datos del Vehículo</h4>
              <Selector setTipo={setTipo} />
              <aside className='grid grid-cols-2 mt-4 gap-2'>
                <BrandSelector brand={brand} autosData={autosData} setModelo={setModelo} setAutoState={setAutoState} setVehiculo={setVehiculo} selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand} />
                <ModelSelector modelo={modelo} autoState={autoState} setFactura={setFactura} setValorFactura={setValorFactura} setInsurance={setInsurance} 
                setSeguro={setSeguro} setVehiculo={setVehiculo} vehiculo={vehiculo} setHibrido={setHibrido} promocion={promocion} setPromocion={setPromocion}
                parametros={parametros} importes={importes} setImportes={setImportes} 
                // modelSelectors={modelSelectors} setModelSelectors={setModelSelectors}
                />
              </aside>
            
              <aside className='grid grid-cols-12 my-4 gap-2 p-fluid'>
                <InputText style={{fontSize: '0.85rem', padding: '.5rem 1rem'}} value={ hibrido.toUpperCase() === 'NO' ? 'Auto de Combustión' : hibrido.toUpperCase() === 'SI' ? 'Auto Hibrido' : 'Tipo de Auto' }
                className="col-span-6 relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" disabled/>
                <InputNumber placeholder='Valor de Factura' value={factura} mode="currency" currency="USD" locale="en-US" disabled
                className="col-span-5 relative block w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 sm:text-sm" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1.5rem', textAlign: 'right'}} />
                <button className='btn-promo text-indigo-400 bg-indigo-100 hover:bg-indigo-400 hover:text-white rounded-full border-2 border-indigo-400' 
                  onClick={handlerPromo}
                ><i className='pi pi-star-fill'></i></button>
              </aside>
              {/* Seguro */}
              <div className={ insurance.length > 0 ? 'grid grid-cols-2 p-fluid gap-2' : 'hidden' } >
                <InputNumber placeholder='Seguro' value={seguro} mode="currency" currency="USD" locale="en-US" disabled
                className="mb-4 relative block w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 sm:text-sm" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}} />
                <aside className='grid grid-cols-7'>
                  <div className='col-span-6'><InsuranceSelector insurance={insurance} setSeguro={setSeguro}/></div>
                  <div className='self-center pb-4'><BadgeInfo message='Recuerda que se seleccionará el seguro con el precio más bajo por defecto.' /></div>
                </aside>
              </div>
              
              {/* Calcular Costos */}
              {/* <div className='grid justify-items-strech mt-5 '> */}
              <div className='grid grid-cols-2 gap-x-2 justify-items-strech mt-5 '>
               <button className='bg-sky-600 hover:bg-sky-700 py-2 px-5 mb-5 rounded-lg text-white justify-self-start'>Calcular</button>
               <button type="button" onClick={limpiarStorage} className='bg-indigo-500 hover:bg-indigo-600 py-2 px-5 mb-5 rounded-lg text-white justify-self-start'>Limpiar Campos</button>
              </div>
              
              <div className='border-t mt-5 lg:block md:hidden sm:hidden hidden'>
                <h4 className='mb-5 font-semibold text-lg mt-5'>Imprimir Archivo</h4>
                {/* <Link to='/cotizacionSA' className="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 mb-5">Cotización Sin Aportación</Link>
                <Link to='/cotizacion' className="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 mb-5">Cotización Con Aportación</Link>
                <Link to='/comparative' className="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2">Comparación de Cotizaciones</Link> */}
                <button onClick={() => openInNewTab('/cotizacionSA')} className="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 mb-5">Cotización Sin Aportación</button>
                <button onClick={() => openInNewTab('/cotizacion')} className="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 mb-5">Cotización Con Aportación</button>
                <button onClick={() => openInNewTab('/comparative')}  className="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2">Comparación de Cotizaciones</button>
              </div>
              {/* <ArrendamientoSA /> */}
            </form>
            <div className='px-10 w-6/6'>
              <div className='border-b pb-4'> <ArrendamientoSA /> </div>
              <Arrendamiento />
              {/* <Link to='/cotizacionSA' className="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 mb-5">Cotizar Sin Aportacion</Link>
              <Link to='/cotizacion' className="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 mb-5">Cotizar Con Aportacion</Link>
              <Link to='/comparative' className="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2">Comparar Cotizaciones</Link> */}
               <div className='border-t mt-5 lg:hidden md:block sm:block'>
                <h4 className='mb-5 font-semibold text-lg mt-5'>Imprimir Archivo</h4>
                <Link to='/cotizacionSA' className="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 mb-5">Cotización Sin Aportación</Link>
                <Link to='/cotizacion' className="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 mb-5">Cotización Con Aportación</Link>
                <Link to='/comparative' className="flex w-full justify-center rounded-md border border-transparent bg-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2">Comparación de Cotizaciones</Link>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  )
}