import React, {useState, useEffect} from 'react'
import { getParametersUrl } from '../helpers/helper'

//Para Manejar el estado de las cotizaciones con Aportacion de forma global
const Context = React.createContext({})

export function CotizacionContextProvider ({children}) {
    const [ valorFactura, setValorFactura ] = useState(0)
    const [ renta, setRenta ] = useState([])
    const [ rentaSA, setRentaSA ] = useState([])
    const [ residual, setResidual ] = useState([])
    
    const [ aportacionInicial, setAportacionInicial ] = useState([])
    const [ tipo, setTipo ] = useState('particular')
    const [ vehiculo, setVehiculo ] = useState('')
    const [ seguro, setSeguro ] = useState(0)
    const [ residual2, setResidual2 ] = useState([])
    const [ residualf5710, setResidualf5710 ] = useState([])
    const [ cotizacion, setCotizacion ] = useState({})
    const [ hibrido, setHibrido ] = useState('')
    const [ parametros, setParametros ] = useState({rate: 0.2, localizador: [0, 0, 0], limite: [0, 0,0,0], comision: [0, 0, 0,0,0] })
    const [ importes, setImportes ] = useState({comision_apertura: 0, localizador: [0, 0, 0]})

    useEffect(() => {
        fetch(getParametersUrl)
            .then(res => res.json())
            .then(result => {
                let obj = {
                    rate: result.tasa,
                    localizador: [result.localizador24, result.localizador36, result.localizador48],
                    limite: [result.limite1, result.limite2,result.limite3,result.limite4],
                    comision: [result.comision1, result.comision2, result.comision3,result.comision4,result.comision5]
                }
                setParametros(obj)
                setImportes({...importes, localizador: [result.localizador24, result.localizador36, result.localizador48]})
            })
            // eslint-disable-next-line
    }, [])

    return (
        <Context.Provider value={{valorFactura, setValorFactura, renta, setRenta, rentaSA, setRentaSA, residual, setResidual, aportacionInicial, setAportacionInicial, 
        tipo, setTipo, vehiculo, setVehiculo, seguro, setSeguro, residual2, setResidual2, residualf5710, setResidualf5710,cotizacion, setCotizacion, hibrido, setHibrido, parametros,
        importes, setImportes}} >
            {children}
        </Context.Provider>
    )
}

export default Context