import { useEffect, useState, useRef } from 'react'
import Cotizador from '../../components/Cotizador/'
//import Header from '../../components/Header'
import Asesor from '../../components/Dashboard/Asesor'
import { Link } from 'wouter'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useLogin } from '../../hooks/useLogin'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { putUserUrl,version_Movex } from '../../helpers/helper'
import { Toast } from 'primereact/toast'

/*Vendedor Page*/
export default function Vendedor ( {isLogged} ) {
  const profile = useRef()
  const toast = useRef(null)
  const { setUser, setRoute, route, user } = useLogin()
  const [ component, setComponent ] = useState('dashboard')

  useEffect(() => {
    setUser(isLogged)
    setRoute('/vendedor')
  }, [isLogged, setRoute, setUser])

  const showInfo = (message) => {
    toast.current.show({severity:'success', summary: 'Success', detail: message, life: 3000})
  }

  const handlerSubmit = (e) => {
    e.preventDefault()
    profile.current.toggle(false)
    let updateUser = {}
    let nombres = '', apellido_materno = '', apellido_paterno = '', contrasenia = ''
    nombres = ( document.getElementById('nombres').value !== undefined && document.getElementById('nombres').value !== null && document.getElementById('nombres').value !== '') 
    ? document.getElementById('nombres').value : user.nombres
    apellido_paterno = ( document.getElementById('apellidoPaterno').value !== undefined && document.getElementById('apellidoPaterno').value !== null && document.getElementById('apellidoPaterno').value !== '' ) 
    ? document.getElementById('apellidoPaterno').value : user.apellido_paterno
    apellido_materno = ( document.getElementById('apellidoMaterno').value !== undefined && document.getElementById('apellidoMaterno').value !== null && document.getElementById('apellidoMaterno').value !== '' ) 
    ? document.getElementById('apellidoMaterno').value : user.apellido_materno
    contrasenia = ( document.getElementById('contrasenia').value !== undefined && document.getElementById('contrasenia').value !== null && document.getElementById('contrasenia').value !== '' ) 
    ? document.getElementById('contrasenia').value : ''
    contrasenia === '' ? updateUser = {id: user.id, nombres: nombres, apellido_paterno: apellido_paterno, apellido_materno: apellido_materno } :
    updateUser = {id: user.id, nombres: nombres, apellido_paterno: apellido_paterno, apellido_materno: apellido_materno, contrasenia: contrasenia }
    console.log(updateUser)

    fetch(putUserUrl, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateUser),
    }).then((res) => res.json())
      .then((result) => {
        result.message === 'Success' && showInfo('Los datos fueron actualizados.')
      })
  }

  return(
    <>
      <Toast ref={toast} />
      <OverlayPanel ref={profile} showCloseIcon id='overlay_panel' style={{backgroundColor: '#f3f4f6'}}>
        <form className='px-4 pb-4 p-fluid' onSubmit={handlerSubmit}>
          <h2 className='font-semibold mb-4 text-center text-lg'>Actualizar Datos del Perfil</h2>
          <InputText id='nombres' defaultValue={user.nombres} style={{height: '2rem', margin: '0.5rem 0'}}
          className="my-4 text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
          <InputText id='apellidoPaterno' defaultValue={user.apellido_paterno} style={{height: '2rem', margin: '0.5rem 0'}}
          className="my-4 text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
          <InputText id='apellidoMaterno' defaultValue={user.apellido_materno} style={{height: '2rem', margin: '0.5rem 0'}}
          className="my-4 text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
          <Password inputId='contrasenia' placeholder='Contraseña' toggleMask inputStyle={{height: '2rem'}}
          className="text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
          <button className='my-5 bg-indigo-500 text-white py-2 w-full rounded-lg hover:bg-indigo-600'>Actualizar</button>
        </form>
      </OverlayPanel>

      <header className="px-5 py-4 bg-gray-900 border-b-4 border-indigo-400 grid grid-cols-2 sticky top-0 absolute z-50">
        <Link to={route}><img src="/logomovex.svg" alt="Movex Logo" className="h-[2.5rem] bg-white px-2 py-1 mx-5 rounded-lg" /></Link>
        <div className='flex flex-cols justify-self-end mr-5'>
          <button className='text-white justify-self-end text-lg hover:bg-gray-700 px-5 py-2 rounded-lg hover:underline hover:underline-offset-4' 
          onClick={() => setComponent('dashboard')}> <i className='pi pi-home mr-2'></i>Inicio</button>
          <button className='text-white justify-self-end text-lg hover:bg-gray-700 px-5 py-2 rounded-lg hover:underline hover:underline-offset-4' 
          onClick={() => setComponent('cotizador')}> <i className='pi pi-money-bill mr-2'></i>Cotizador</button>

          <button className='text-white justify-self-end text-lg hover:bg-gray-700 px-5 py-2 rounded-lg hover:underline hover:underline-offset-4' 
          onClick={(e) => profile.current.toggle(e)}> <i className='pi pi-user-edit mr-2'></i>Editar Perfil</button>
          <Link to='/logout' className="ml-5 text-white justify-self-end mx-2 py-2 text-lg hover:bg-gray-700 px-5 rounded-lg" onClick={() => {
            setRoute('/')
            setUser({})
          }}><i className="pi pi-sign-out mr-2"></i>Cerrar Sesión</Link> 
        </div>
      </header>
      {/* <section className='flex flex-row'>
        <aside className='sticky top-0 h-screen w-64 bg-gray-900 rounded-r-[2.4rem] ring-8 ring-indigo-300'>
          <div className='my-20 ml-3'>
            
          </div>
        </aside>
      </section> */}
      
      <section className='mx-20'>
        { component === 'dashboard' ? <Asesor user={user} /> 
        : component === 'cotizador' && <div className='mx-20 mt-10'><Cotizador /></div> }
      </section>

      {/* <div className='bg-gray-900 px-10 grid content-center fixed bottom-0 w-full'><p className='text-white text-[0.8rem] py-1'>Cotizador Movex v.1.3.2 by BMTools</p></div> */}
      <div className='bg-gray-900 px-10 grid content-center fixed bottom-0 w-full'><p className='text-white text-[0.8rem] py-1'>{version_Movex}</p></div>
    </>
  )
}